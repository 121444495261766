import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/sass/membersOne.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MembersOne(props) {
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  const { t } = useTranslation();
  const handleClickActive = (activeSlider) => {
    props.activeSliderMembers(activeSlider);
  };

  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );

    var headerOffset = 250;
    var elementPosition = target.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="members-one">
      <div className="members-one-backgroud-nav">
        <LazyLoadImage
          className="backgroud-"
          src={require("../../assets/images/Members/backgroud-members-one-frame.webp")}
          alt=""
        />
      </div>
      <div className="members-one-backgroud">
        <div className="members-one-contain">
          <div className="content-left-col">
            <div className="content-left-backroud-frame">
              <LazyLoadImage
                className="backroud-frame-bottom"
                src={require("../../assets/images/Members/backgroud-frame-members-one.webp")}
                alt=""
              />
            </div>
            <div
              className={`content-left  ${
                language === "EN" || language === "en" || language === "en-US"
                  ? ""
                  : "content-left-VN"
              }`}
            >
              <div className="content-left-title">
                {t("TMA.Technology.Group.Members")}
              </div>
            </div>
          </div>
        </div>
        <div className="content-box">
          <div className="box-contain">
            <div className="box-top">
              <div className="box-content box-content-tma-solution">
                <Link
                  className="a-href desktop-members"
                  to={`${
                    language === "EN" ||
                    language === "en" ||
                    language === "en-US"
                      ? "/members"
                      : "/thanh-vien"
                  }`}
                  onClick={() => handleClickActive(11)}
                >
                  <div className="item-box">
                    <div className="item-box-logo">
                      <LazyLoadImage
                        className="logomemberone-1"
                        src={require("../../assets/images/Members/logo-tma.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name">
                      <span>TMA Solutions</span>
                    </div>
                    <div className="item-box-text item-box-text-1">
                      <div>
                        {t(
                          "Reliable.Technology.&.Innovation.Partner.for.Companies.Worldwide"
                        )}
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className="a-href mobile-members"
                  to={`#tma-solution`}
                  onClick={(e) => onPress(e)}
                  data-to-scrollspy-id={"tma-solution"}
                >
                  <div className="item-box">
                    <div className="item-box-logo">
                      <LazyLoadImage
                        className="logomemberone-1"
                        src={require("../../assets/images/Members/logo-tma.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name">
                      <span>TMA Solutions</span>
                    </div>
                    <div className="item-box-text item-box-text-1">
                      <div>
                        {t(
                          "Reliable.Technology.&.Innovation.Partner.for.Companies.Worldwide"
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="box-content box-content-tma-bd">
                <Link
                  className="a-href desktop-members"
                  to={`${
                    language === "EN" ||
                    language === "en" ||
                    language === "en-US"
                      ? "/members"
                      : "/thanh-vien"
                  }`}
                  onClick={() =>
                    handleClickActive(
                      language === "EN" ||
                        language === "en" ||
                        language === "en-US"
                        ? 17
                        : 16
                    )
                  }
                >
                  <div className="item-box item-box-2">
                    <div className="item-box-logo-BD">
                      <LazyLoadImage
                        className="logomemberone-2"
                        src={require("../../assets/images/Members/logo-tma-BD.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name item-box-bd">
                      <span>{t("TMA.Solutions.Binh.Đinh")}</span>
                    </div>
                    <div className="item-box-text item-box-text-bd">
                      <div>
                        {t("Leading.technolgy.center.in.central.Vietnam")}
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className="a-href mobile-members"
                  to={`#tma-solution-binh-dinh`}
                  onClick={(e) => onPress(e)}
                  data-to-scrollspy-id={"tma-solution-binh-dinh"}
                >
                  <div className="item-box item-box-2">
                    <div className="item-box-logo-BD">
                      <LazyLoadImage
                        className="logomemberone-2"
                        src={require("../../assets/images/Members/logo-tma-BD.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name item-box-bd">
                      <span>{t("TMA.Solutions.Binh.Đinh")}</span>
                    </div>
                    <div className="item-box-text item-box-text-bd">
                      <div>
                        {t("Leading.technolgy.center.in.central.Vietnam")}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="box-bottom">
              <div className="box-content box-content-tma-inno">
                <Link
                  to={`${
                    language === "EN" ||
                    language === "en" ||
                    language === "en-US"
                      ? "/members"
                      : "/thanh-vien"
                  }`}
                  onClick={() => handleClickActive(20)}
                  className="a-href desktop-members"
                >
                  <div className="item-box">
                    <div className="item-box-logo">
                      <LazyLoadImage
                        className="logomemberone-3"
                        src={require("../../assets/images/Members/logo-tma-inno.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name">
                      <span>TMA Innovation</span>
                    </div>
                    <div
                      className={
                        language === "en" || language === "EN"
                          ? "item-box-text  "
                          : "item-box-text-VN-INNO item-box-text"
                      }
                    >
                      <div>
                        {t(
                          "Innovative.tech.products.and.solutions.for.various.industries"
                        )}
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  className="a-href mobile-members"
                  to={`#tma-innovation`}
                  onClick={(e) => onPress(e)}
                  data-to-scrollspy-id={"tma-innovation"}
                >
                  <div className="item-box">
                    <div className="item-box-logo">
                      <LazyLoadImage
                        className="logomemberone-1"
                        src={require("../../assets/images/Members/logo-tma-inno.webp")}
                        alt=""
                      />
                    </div>
                    <div className="item-box-name">
                      <span>TMA Innovation</span>
                    </div>
                    <div
                      className={
                        language === "en" || language === "EN"
                          ? "item-box-text  "
                          : "item-box-text-VN-INNO item-box-text"
                      }
                    >
                      <div>
                        {t(
                          "Innovative.tech.products.and.solutions.for.various.industries"
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <a
                href="https://www.tmasolutions.com.au/architectservice/"
                target="_blank"
                rel="noreferrer"
                className="item-archi"
              >
                <div className="item-box  item-box-archi">
                  <div className="item-box-logo">
                    <LazyLoadImage
                      className="logomemberone-1"
                      src={require("../../assets/images/Members/logo-tma.webp")}
                      alt=""
                    />
                  </div>
                  <div className="item-box-name">
                    <span>TMA Architecture Design</span>
                  </div>
                  <div
                    className={
                      language === "en" || language === "EN"
                        ? "item-box-text  "
                        : "item-box-text-VN-INNO item-box-text"
                    }
                  >
                    <div>
                      {t(
                        "Drafting.services.to.architecture.construction.and.building.firms.in.Australia"
                      )}
                    </div>
                  </div>
                </div>
              </a>
              {/* <div className="box-content box-content-tma-inno"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembersOne;
