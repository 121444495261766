import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import data from "../config/config";
// import background from "../assets/images/AboutUs/background-about.webp"
import "../assets/sass/aboutUs.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import generalNumberApi from "../api/generalNumberApi";

const AboutUs = () => {
  const { t } = useTranslation();
  const [dataNumbersTMA, setDataNumbersTMA] = useState();

  const i18nextLng =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await generalNumberApi.getNumberTma();
        setDataNumbersTMA(data?.data);
      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };
    fetch();
  }, []);

  const formatNumberVN = (string) => {
    const number = parseFloat(string.replace(/[^\d]/g, ""));
    const formattedNumber =
      number.toLocaleString("vi-VN") + (string.includes("+") ? "+" : "");

    return formattedNumber;
  };

  return (
    <div className="d-flex menu about-us">
      <div className="about-us-backgroud">
        <LazyLoadImage
          className="backgroud-image"
          src={require("../assets/images/AboutUs/backgroud-frame-overview.webp")}
          alt="image"
        />
        <LazyLoadImage
          className="backgroud-image-mobile"
          src={require("../assets/images/AboutUs/backgroud-frame-overview-767.webp")}
          alt="image"
        />
      </div>
      <div className="about-us-content col-lg-6 col-xs-12">
        {i18nextLng === "EN" ||
        i18nextLng === "en" ||
        i18nextLng === "en-US" ? (
          <div className="about-us-title">{t("Overview")}</div>
        ) : (
          <div className="about-us-title">
            <div className="title-desktop">
              <div>Tập Đoàn</div>
              <div>Công Nghệ TMA</div>
            </div>
            <div className="title-tablet">
              <div>Tập Đoàn Công Nghệ TMA</div>
            </div>
          </div>
        )}

        <div className="about-us-span">
          <div>{t("about.us.content1")}</div>
          <div className="padding-top-12">{t("about.us.content2")}</div>
          <div className="padding-top-12">{t("about.us.content3")}</div>
        </div>
        <div className="about-us-box d-flex">
          <div className="col-6 col-box-left">
            <div className="d-flex box-content">
              <div>
                <LazyLoadImage
                  className="box-content-img"
                  src={require("../assets/images/AboutUs/icon-develope.webp")}
                  alt="image"
                />
              </div>
              <div className="box-content-text">
                <b>
                  {dataNumbersTMA
                    ? dataNumbersTMA[0]?.number
                    : data.totalCompanyEstablishmentYear}
                </b>{" "}
                {t("years")}
              </div>
            </div>
            <div className="d-flex box-content">
              <div>
                <LazyLoadImage
                  className="box-content-img"
                  src={require("../assets/images/AboutUs/icon-countries.webp")}
                  alt="image"
                />
              </div>
              <div className="box-content-text">
                {t("clients.from")}{" "}
                <b>
                  {dataNumbersTMA
                    ? dataNumbersTMA[2]?.number
                    : data.customerCountries}{" "}
                  {t("countries")}
                </b>
              </div>
            </div>
          </div>
          <div className="col-6 col-box-right">
            <div className="d-flex box-content">
              <div>
                <LazyLoadImage
                  className="box-content-img"
                  src={require("../assets/images/AboutUs/icon-software-engineers.webp")}
                  alt="image"
                />
              </div>
              <div className="box-content-text">
                <b>
                  {dataNumbersTMA
                    ? i18nextLng === "EN" ||
                      i18nextLng === "en" ||
                      i18nextLng === "en-US"
                      ? dataNumbersTMA[1]?.number
                      : formatNumberVN(dataNumbersTMA[1]?.number)
                    : data.engineer}
                </b>{" "}
                {t("software.engineers")}
              </div>
            </div>
            <div className="d-flex box-content">
              <div>
                <LazyLoadImage
                  className="box-content-img"
                  src={require("../assets/images/AboutUs/icon-offices.webp")}
                  alt="image"
                />
              </div>
              <div className="box-content-text">
                {t("offices.in")}{" "}
                <b>
                  {data.officeCountries} {t("countries")}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-xs-12 col-right-page">
        <div className="about-us-img">
          <div className="about-us-backroud-frame">
            <LazyLoadImage
              src={require("../assets/images/AboutUs/backgroud-frame.webp")}
              alt="image"
            />
          </div>
          <div className="d-flex">
            <div className="image-about-us-lab6">
              <LazyLoadImage
                className="image-about-us-lab6-nav"
                src={require("../assets/images/AboutUs/image-about-us-lab6.webp")}
                alt="image"
              />
            </div>
            <div className="image-about-us-lab8">
              <LazyLoadImage
                className="image-about-us-lab8-nav"
                src={require("../assets/images/AboutUs/image-about-us-lab8.webp")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
