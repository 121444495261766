import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import generalNumberApi from "../api/generalNumberApi";
import "../assets/sass/technologies.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import data from "../config/config";

const Technologies = () => {
  const { t } = useTranslation();
  const [dataNumbersTMA, setDataNumbersTMA] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await generalNumberApi.getNumberTma();
        setDataNumbersTMA(data?.data);
      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };
    fetch();
  }, []);

  const formatNumberVN = (string) => {
    const number = parseFloat(string.replace(/[^\d]/g, ""));
    const formattedNumber =
      number.toLocaleString("vi-VN") + (string.includes("+") ? "+" : "");

    return formattedNumber;
  };

  const i18nextLng =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");

  return (
    <div>
      <div className="technologies-cotnainer">
        <div className="members-two-backgroud">
          <LazyLoadImage
            className="backgroud-image"
            src={require("../assets/images/AboutUs/backgroud-frame-big.webp")}
            alt="image"
          />
        </div>

        <div className="d-flex menu technologies-page">
          <div className="about-us-content col-lg-6 col-xs-12 ">
            <div className="technologies-title title-desktop">
              <div>{`${
                dataNumbersTMA
                  ? dataNumbersTMA[0]?.number
                  : data.totalCompanyEstablishmentYear
              } ${t("title.technologies.top")}`}</div>
              <div>{t("title.technologies.bottom")}</div>
            </div>

            <div className="technologies-title title-mobile">
              {t("title.technologies.top")} {t("title.technologies.bottom")}
            </div>
            <div className="technologies-box d-flex">
              <div className="col-6 col-box-left">
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-4g-5g.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">4G/5G</div>
                </div>
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-ai-ml.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">AI/ML</div>
                </div>
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-data-analytics.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">Data Analytics</div>
                </div>
              </div>
              <div className="col-6 col-box-right box-right-mr">
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-iot.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">IoT</div>
                </div>
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-automotive.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">Cloud</div>
                </div>
                <div className="d-flex box-content">
                  <div className="box-icon">
                    <LazyLoadImage
                      className="box-icon-img"
                      src={require("../assets/images/Technologies/icon-robot.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="box-text">Robotics</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 technologies-backgroud-right">
            <div className="technologies-img">
              <div className="technologies-backroud-frame">
                <LazyLoadImage
                  src={require("../assets/images/AboutUs/backgroud-frame.webp")}
                  alt="image"
                />
              </div>
              <div className="technologies-image-backgroud">
                <div className="technologies-backgroud-one">
                  <LazyLoadImage
                    className="technologies-image-one"
                    src={require("../assets/images/Technologies/image1.webp")}
                    alt="image"
                  />
                </div>
                <div className="d-flex technologies-image-bottom">
                  <div className="image-left-techonology">
                    <LazyLoadImage
                      className="technologies-image-two"
                      src={require("../assets/images/Technologies/image2.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="image-right-techonology">
                    <LazyLoadImage
                      className="technologies-image-three"
                      src={require("../assets/images/Technologies/image3.webp")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
