import React from "react";
import { useTranslation } from "react-i18next";
import iconSolutionConsulting from "../../assets/images/SolutionsServices/icon-Go-Automation.webp";
import iconGoDigital from "../../assets/images/SolutionsServices/icon-Go-Digital.webp";
import iconGoInnovative from "../../assets/images/SolutionsServices/icon-Go-Innovative.webp";
import iconGoMobile from "../../assets/images/SolutionsServices/icon-Go-Mobile.webp";
import iconGoInteractive from "../../assets/images/SolutionsServices/icon-Go-Interactive.webp";
import iconGoOnline from "../../assets/images/SolutionsServices/icon-Go-Online.webp";
import iconGoCloud from "../../assets/images/SolutionsServices/icon-Go-Cloud.webp";
import iconGoSmart from "../../assets/images/SolutionsServices/icon-Go-Smart.webp";
import "../../assets/sass/membersFive.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MembersFive() {
  const { t } = useTranslation();
  return (
    <div className="members-five">
      <div className="members-five-backgroud">
        <div className="members-five-content container">
          <div className="title">{t("Services.&.Solutions")}</div>
          <div className="content">
            <div className="d-flex content-momberfive">
              <div className="content-image">
                <LazyLoadImage
                  className="image"
                  src={require("../../assets/images/Members/image-pageFive-2.webp")}
                  alt="image"
                />
              </div>
              <div className="d-flex content-box">
                <div className="Solutions-title-hr-nav-1  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-2  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-3  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-4  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-5  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-6  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-7  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="Solutions-title-hr-nav-8  ">
                  <div className="Solutions-title-hr" />
                </div>
                <div className="content-box-left">
                  <div className="box box-member-1">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconSolutionConsulting}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Automation</div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoDigital}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Digital</div>
                    </div>
                  </div>
                  <div className="box box-member-1">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoMobile}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Mobile</div>
                    </div>
                  </div>
                  <div className="box-four">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoOnline}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Online</div>
                    </div>
                  </div>
                </div>
                <div className="content-box-right ">
                  <div className="box box-member-1">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoSmart}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Smart</div>
                    </div>
                  </div>
                  <div className="box ">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoInnovative}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Innovative</div>
                    </div>
                  </div>
                  <div className="box  box-member-1">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoInteractive}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Interactive</div>
                    </div>
                  </div>
                  <div className="box-four">
                    <div className="box-backgroud">
                      <div>
                        <LazyLoadImage
                          className="memberfive-icon"
                          src={iconGoCloud}
                          alt="image"
                        />
                      </div>
                      <div className="box-text">Go Cloud</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembersFive;
