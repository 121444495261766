import React, { useState, useEffect } from "react";
import useLocation from "../hooks/useLocation";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import ClientsMap from "./ClientsMap";
import History from "./History/HistoryOne";
import HistoryTwo from "./History/HistoryTwo";
import Technologies from "./Technologies";
import SolutionsOne from "./Solutions/SolutionsOne";
import SolutionsTwo from "./Solutions/SolutionsTwo";
import SolutionsThree from "./Solutions/SolutionsThree";
import SolutionsFour from "./Solutions/SolutionsFour";
import MembersOne from "./Members/MembersOne";
import MembersTwo from "./Members/MembersTwo";
import MembersThree from "./Members/MembersThree";
import MembersFour from "./Members/MembersFour";
import MembersFive from "./Members/MembersFive";
import StrongQualityFoundation from "./Members/StrongQualityFoundation";
import LargeTalentPool from "./Members/LargeTalentPool";
import ExperiencedInLarge from "./Members/ExperiencedInLarge";
import TMASolutionsBinhDinh from "./Members/TMASolutionsBinhDinh";
import TMAInnovationPark from "./Members/TMAInnovationPark";
import ResearchCenter from "./Members/ResearchCenter";
import DataScienceLab from "./Members/DataScienceLab";
import BenefitsForSetting from "./Members/BenefitsForSetting";
import TMAInnovation from "./Members/TMAInnovation";
import TMAInnovationVN from "./Members/TMAInnovationVN";
import TechnologyProduct from "./Members/TechnologyProduct";
import UniversityCooperation from "./Members/universityCooperation";
import MediaAwards from "./MediaAwards";
import HeaderMembers from "./MenuMembers";
// import Christmas from "./Christmas"
// import Infographics from "./Infographics"
import "../assets/sass/home.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Mousewheel, Pagination, Navigation, HashNavigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollSpy from "react-ui-scrollspy";

function Home(props) {
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  const pathname = useLocation();
  const [namePage, setNamePage] = useState("home");
  let [activeSlider, setActiveSlider] = useState(0);
  const [swiper, setSwiper] = React.useState(null);

  const sendData = (urlPage) => {
    props.parentCallback(urlPage);
  };
  const sendPageName = (namePage) => {
    props.classPage(namePage);
  };
  const sendStatus = (data) => {
    props.StatusCall(data);
  };
  const sendActive = (activeSlider) => {
    props.callbackActive(activeSlider);
  };

  // For EN
  const listPageNameEN = [
    // "christmas",
    // "infographics",
    "home",
    "about-us",
    "clients-map",
    "history",
    "history",
    "technologies",
    "solutionsOne",
    "solutionsTwo",
    "solutionsThree",
    "solutionsFour",
    "membersOne",
    "membersTwo",
    "membersThree",
    "membersFive",
    "strongQualityFoundation",
    "largeTalentPool",
    "experiencedInLarge",
    "TMASolutionsBinhDinh",
    "TMAInnovationPark",
    "BenefitsForSetting",
    "TMAInnovation",
    "mediaAwards",
    "contactUs",
  ];
  const listPageURLEN = [
    // "/",
    // "/",
    "/",
    "/about-us",
    "/about-us",
    "/history",
    "/history",
    "/technologies",
    "/solutions",
    "/solutions",
    "/solutions",
    "/solutions",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/members",
    "/media",
    "/contact-us",
  ];

  // For VN
  const listPageNameVN = [
    // "christmas",
    // "infographics",
    "home",
    "about-us",
    "clients-map",
    "history",
    "history",
    "technologies",
    "solutionsOne",
    "solutionsTwo",
    "solutionsThree",
    "solutionsFour",
    "membersOne",
    "membersFour",
    "membersTwo",
    "membersThree",
    "membersFive",
    "strongQualityFoundation",
    "TMASolutionsBinhDinh",
    "TMAInnovationPark",
    "researchCenter",
    "dataScienceLab",
    "TMAInnovationVN",
    "TechnologyProduct",
    "UniversityCooperation",
    "mediaAwards",
    "contactUs",
  ];
  const listPageURLVN = [
    // "/",
    // "/",
    "/",
    "/ve-chung-toi",
    "/ve-chung-toi",
    "/lich-su-hinh-thanh",
    "/lich-su-hinh-thanh",
    "/cong-nghe",
    "/giai-phap",
    "/giai-phap",
    "/giai-phap",
    "/giai-phap",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/thanh-vien",
    "/hop-tac-dai-hoc",
    "/thu-vien-hinh-anh",
    "/lien-he",
  ];

  useEffect(() => {
    sendPageName(namePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePage]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlider]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(props.activeSlider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeSlider]);

  const activeSliderMembers = (active) => {
    if (swiper) {
      swiper.slideTo(active);
    }
  };
  // END
  const handleSlider = async (e) => {
    let urlPage = "/";
    if (language === "EN" || language === "en" || language === "en-US") {
      urlPage = listPageURLEN[e];
      setNamePage(listPageNameEN[e]);
    } else {
      urlPage = listPageURLVN[e];
      setNamePage(listPageNameVN[e]);
    }

    await window.history.pushState("", "", urlPage);
    await setActiveSlider(e);
    await sendActive(e);
    await sendData(urlPage);
    await sendStatus(true);
  };

  useEffect(() => {
    let urlPage = "/";
    var index;
    if (language === "EN" || language === "en" || language === "en-US") {
      if (pathname === "/university-cooperative") {
        window.history.pushState("", "", urlPage);
        window.location.reload();
      }
      index = listPageURLEN.indexOf(pathname);
      setActiveSlider(index);
      setNamePage(listPageNameEN[index]);
    } else {
      index = listPageURLVN.indexOf(pathname);
      setActiveSlider(index);
      setNamePage(listPageNameVN[index]);
    }
    sendStatus();
    localStorage.setItem("pathname", pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <div className="none-desktop">
        {language === "EN" || language === "en" || language === "en-US" ? (
          <div className={`home-page ${namePage}`} id="myRange">
            {activeSlider > -1 ? (
              <Swiper
                spaceBetween={30}
                // speed={300}
                // loop={true}
                centeredSlides={true}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Mousewheel, Pagination, Navigation, HashNavigation]}
                onRealIndexChange={(e) => {
                  handleSlider(e.activeIndex);
                }}
                onSwiper={(s) => {
                  setSwiper(s);
                }}
                className="mySwiper"
              >
                {/* <SwiperSlide>
                  <Christmas />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide> */}
                {/* <SwiperSlide>
                  <Infographics />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_blue.webp")}
                    alt="page_2-1"
                    className="pageNumber"
                  />
                </SwiperSlide> */}
                <SwiperSlide>
                  <Banner />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_3-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <AboutUs />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-2.webp")}
                    alt="page_1-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientsMap />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-2.webp")}
                    alt="page_2-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <History />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-2.webp")}
                    alt="page_1-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <HistoryTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-2.webp")}
                    alt="page_2-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Technologies />{" "}
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_lineBlue.webp")}
                    alt="page_1-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsOne />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-4.webp")}
                    alt="page_1-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-4.webp")}
                    alt="page_2-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsThree />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_3-4.webp")}
                    alt="page_3-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsFour />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_4-4.webp")}
                    alt="page_4-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersOne activeSliderMembers={activeSliderMembers} />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-11.webp")}
                    alt="page_1-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-11.webp")}
                    alt="page_2-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersThree />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_3-11.webp")}
                    alt="page_3-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersFive />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_4-11.webp")}
                    alt="page_4-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StrongQualityFoundation />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_5-11.webp")}
                    alt="page_5-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <LargeTalentPool />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_6-11.webp")}
                    alt="page_6-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ExperiencedInLarge />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_7-11.webp")}
                    alt="page_7-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMASolutionsBinhDinh />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_8-11.webp")}
                    alt="page_8-11"
                    className="pageNumber image-page-scale"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMAInnovationPark />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_9-11.webp")}
                    alt="page_9-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <BenefitsForSetting />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_10-11.webp")}
                    alt="page_10-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMAInnovation />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_11-11.webp")}
                    alt="page_11-11"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MediaAwards />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ContactUs />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_lineBlue.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <div id="numberSlides"></div>
              </Swiper>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className={`home-page ${namePage}`}>
            {activeSlider > -1 ? (
              <Swiper
                spaceBetween={30}
                // speed={300}
                // loop={true}
                centeredSlides={true}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Mousewheel, Pagination, Navigation, HashNavigation]}
                onRealIndexChange={(e) => {
                  handleSlider(e.activeIndex);
                }}
                onSwiper={(s) => {
                  setSwiper(s);
                }}
                className="mySwiper"
              >
                 {/* <SwiperSlide>
                  <Christmas />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide> */}
                {/* <SwiperSlide>
                  <Infographics />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_blue.webp")}
                    alt="page_2-1"
                    className="pageNumber"
                  />
                </SwiperSlide> */}
                <SwiperSlide>
                  <Banner />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_3-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <Banner />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide> */}
                <SwiperSlide>
                  <AboutUs />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-2.webp")}
                    alt="page_1-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientsMap />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-2.webp")}
                    alt="page_2-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <History />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-2.webp")}
                    alt="page_1-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <HistoryTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-2.webp")}
                    alt="page_2-2"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Technologies />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_lineBlue.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsOne />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-4.webp")}
                    alt="page_1-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-4.webp")}
                    alt="page_2-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsThree />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_3-4.webp")}
                    alt="page_3-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SolutionsFour />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_4-4.webp")}
                    alt="page_4-4"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersOne activeSliderMembers={activeSliderMembers} />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-12.webp")}
                    alt="page_1-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersFour />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_2-12.webp")}
                    alt="page_2-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersTwo />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_3-12.webp")}
                    alt="page_3-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersThree />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_4-12.webp")}
                    alt="page_4-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MembersFive />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_5-12.webp")}
                    alt="page_5-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StrongQualityFoundation />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_6-12.webp")}
                    alt="page_6-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMASolutionsBinhDinh />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_7-12.webp")}
                    alt="page_7-12"
                    className="pageNumber image-page-scale "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMAInnovationPark />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_8-12.webp")}
                    alt="page_8-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ResearchCenter />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_9-12.webp")}
                    alt="page_9-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DataScienceLab />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_10-12.webp")}
                    alt="page_10-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TMAInnovationVN />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_11-12.webp")}
                    alt="page_11-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TechnologyProduct />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_12-12.webp")}
                    alt="page_12-12"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <UniversityCooperation />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <MediaAwards />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ContactUs />
                  <LazyLoadImage
                    src={require("../assets/images/imgNumberPages/page_1-1_lineBlue.webp")}
                    alt="page_1-1"
                    className="pageNumber"
                  />
                </SwiperSlide>
              </Swiper>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      {/* mobile */}
      <div className="mobile-page">
        {
          // EN
          language === "EN" || language === "en" || language === "en-US" ? (
            <div>
              <div id="/">
                <Banner />
              </div>
              {/* AboutUs */}
              <div id="/about-us">
                <AboutUs />
              </div>
              <div>
                <ClientsMap />
              </div>
              {/* history */}
              <div id="/history">
                <History />
              </div>
              <div>
                <HistoryTwo />
              </div>
              {/* Technologies */}
              <div id="/technologies">
                <Technologies />
              </div>
              {/* solutions */}
              <div id="/solutions">
                <SolutionsOne />
              </div>

              <div>
                <SolutionsTwo />
              </div>
              <div>
                <SolutionsThree />
              </div>
              <div>
                <SolutionsFour />
              </div>
              {/* Members */}
              <div>
                <div id="/members">
                  <MembersOne />
                </div>

                <div>
                  <HeaderMembers />
                  <ScrollSpy
                    scrollThrottle={100}
                    activeClass="active-scroll-spy"
                  >
                    <div id="tma-solution">
                      <MembersTwo />
                      <MembersThree />
                      <MembersFive />
                      <StrongQualityFoundation />
                      <LargeTalentPool />
                      <ExperiencedInLarge />
                    </div>
                    <div id="tma-solution-binh-dinh">
                      <TMASolutionsBinhDinh />
                      <TMAInnovationPark />
                      <BenefitsForSetting />
                    </div>
                    <div id="tma-innovation">
                      <TMAInnovation />
                    </div>
                  </ScrollSpy>
                </div>
              </div>

              <div id="/media">
                <MediaAwards />
              </div>
              <div id="/contact-us">
                <ContactUs />
              </div>
            </div>
          ) : (
            // VN
            <div>
              <div id="/">
                <Banner />
              </div>
              {/* AboutUs  */}
              <div id="/ve-chung-toi">
                <AboutUs />
              </div>
              <div>
                <ClientsMap />
              </div>

              {/* history */}
              <div id="/lich-su-hinh-thanh">
                <History />
              </div>
              <div>
                <HistoryTwo />
              </div>
              {/* Technologies */}
              <div id="/cong-nghe">
                <Technologies />
              </div>
              {/* solutions */}
              <div id="/giai-phap">
                <SolutionsOne />
              </div>
              <div>
                <SolutionsTwo />
              </div>
              <div>
                <SolutionsThree />
              </div>
              <div>
                <SolutionsFour />
              </div>
              {/* Members */}
              <div id="/thanh-vien">
                <MembersOne />
              </div>
              <div>
                <HeaderMembers />
                <ScrollSpy
                  scrollThrottle={300}
                  useBoxMethod
                  activeClass="active-scroll-spy"
                >
                  <div id="tma-solution">
                    <MembersFour />
                    <MembersTwo />
                    <MembersThree />
                    <MembersFive />
                    <StrongQualityFoundation />
                  </div>

                  <div id="tma-solution-binh-dinh">
                    <TMASolutionsBinhDinh />
                    <TMAInnovationPark />
                    <ResearchCenter />
                    <DataScienceLab />
                  </div>
                  <div id="tma-innovation">
                    <TMAInnovationVN />
                    <TechnologyProduct />
                  </div>
                </ScrollSpy>
              </div>

              <div id="/hop-tac-dai-hoc">
                <UniversityCooperation />
              </div>
              <div id="/thu-vien-hinh-anh">
                <MediaAwards />
              </div>
              <div id="/lien-he">
                <ContactUs />
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default Home;
