const i18nextLng =
  localStorage.getItem("lang") === null
    ? localStorage.getItem("i18nextLng")
    : localStorage.getItem("lang");

const engineer =
  i18nextLng === "en" || i18nextLng === "EN" || i18nextLng === "en-US"
    ? "4,000"
    : "4.000";
//get total company establishment Year
const establishmentYear = 1997;
const newYear = new Date().getFullYear();
const newMonth = new Date().getMonth();
const Years =
  newMonth >= 1 ? newYear - establishmentYear : newYear - establishmentYear - 1;

const data = {
  companyEstablishmentYear: 1997,
  engineer: engineer,
  officeCountries: 7,
  customerCountries: 30,
  engineerEcommerce: 400,
  engineerTelecom: "2,000",
  engineerDataAnalytics: 600,
  engineerIOT: "300+",
  engineerApplication: "3,000",
  engineerFinance: 600,
  engineerHealthcare: 600,
  engineerTesting: "800+",
  totalCompanyEstablishmentYear: Years,
};

export default data;
