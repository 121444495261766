import React, { useState } from 'react';
import i18next from 'i18next';
import '../assets/sass/changeLanguage.scss';

function ChangeLanguageMobile(props) {
	const i18nextLng =
		localStorage.getItem('lang') === null ? localStorage.getItem('i18nextLng') : localStorage.getItem('lang');
	const pathName = props.dataUrl;
	const hostName = window.location.hostname;
	const [textLanguage, setTextLanguage] = useState(i18nextLng);
	const onChangeLanguage = value => {
			i18next.changeLanguage(value);
			setTextLanguage(value);
			localStorage.setItem('lang', value);
			sendData(value);
			window.location.reload();
			if (hostName === 'localhost') {
				return;
			} else {
				if (value === 'vi') {
					window.location.replace(`https://www.tmatechgroup.vn${pathName}`);
				} else {
					window.location.replace(`https://www.tmatechgroup.com${pathName}`);
				}
			}
		},
		sendData = lang => {
			// props.Callback(lang);
		};

	function myFunction() {
		document.getElementById('myDropdown').classList.toggle('show');
	}

	// Close the dropdown if the user clicks outside of it
	window.onclick = function (event) {
		if (!event.target.matches('.dropbtn')) {
			var dropdowns = document.getElementsByClassName('dropdown-content');
			var i;
			for (i = 0; i < dropdowns.length; i++) {
				var openDropdown = dropdowns[i];
				if (openDropdown.classList.contains('show')) {
					openDropdown.classList.remove('show');
				}
			}
		}
	};

	return (
		<div className="change-language-mobile">
			<div className="container-change-language">
				<div className="change-language-div">
					<div className="dropdown">
						<button onClick={myFunction} className="dropbtn">
							{textLanguage === 'vi' || textLanguage === 'vi-VN' ? 'VN' : 'EN'}
						</button>
						<div id="myDropdown" className="dropdown-content">
							<span
								className={
									textLanguage === 'vi' || textLanguage === 'vi-VN'
										? 'change-language-span cursor set-color-change-language'
										: 'change-language-span cursor '
								}
								onClick={() => onChangeLanguage('vi')}
							>
								VN
							</span>
							<span
								className={
									textLanguage === 'en' || textLanguage === 'en-US'
										? 'change-language-span cursor set-color-change-language '
										: 'change-language-span cursor '
								}
								onClick={() => onChangeLanguage('en')}
							>
								EN
							</span>
						</div>
					</div>
					<span className="change-language-span cursor">|</span>
				</div>
			</div>
		</div>
	);
}

export default ChangeLanguageMobile;
