import React from "react";
import { useTranslation } from "react-i18next";
import "../assets/sass/clientsMap.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ClientsMap = () => {
  const { t } = useTranslation();
  return (
    <div className="clients-map">
      <div className="clients-map-backgroud">
        <div className="clients-map-content">
          <div className="clients-map-text">
            {t("Clients.from.30.countries")}
          </div>
          <div className="clients-map-img">
            <LazyLoadImage
              src={require("../assets/images/ClientsMap/image-clients-map.webp")}
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsMap;
