import React from "react";
import "../../assets/sass/largeTalentPool.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function LargeTalentPool() {
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  return (
    <div className="large-talent-pool">
      <div className="large-talent-pool-backgroud-image">
        <LazyLoadImage
          className="image-backgroud"
          src={require("../../assets/images/Members/large-talent-pool-backgroud.webp")}
          alt="image"
        />
      </div>
      <div className="large-talent-pool-backgroud">
        <div className="large-talent-pool-contain container">
          <div className="title">
            <div>Large Talent Pool &</div>
            <div>Well-Established Training System</div>
          </div>
          <div className="content">
            <div className="content-image-desktop">
              <LazyLoadImage
                src={require("../../assets/images/Members/image-content-large-tablent-en.webp")}
                alt="image"
                className="image_content"
              />
            </div>
            <div className="content-image">
              <div className="box-left">
                <div className="box-content col-6">
                  <div className="box">
                    <div className="content-icon">
                      <LazyLoadImage
                        className="box-icon"
                        src={require("../../assets/images/Members/Vector.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="box-title">
                      <p>
                        <span> University Program with: </span>
                        <br />
                        65 university
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <div className="content-icon">
                      <LazyLoadImage
                        className="box-icon"
                        src={require("../../assets/images/Members/Vector-1.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="box-title">
                      <p>
                        10.000+
                        <br />
                        <span>resumes/year </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <div className="content-icon">
                      <LazyLoadImage
                        className="box-icon"
                        src={require("../../assets/images/Members/Vector-2.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="box-title">
                      <p>
                        1,000 <span>intern/year </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <div className="content-icon">
                      <LazyLoadImage
                        className="box-icon"
                        src={require("../../assets/images/Members/Vector-3.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="box-title">
                      <p>
                        100+ <span>courses/year </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right" id="group">
                <div className="box-content col-6">
                  <div className="box">
                    <LazyLoadImage
                      className="box-icon"
                      src={require("../../assets/images/Members/Frame-8504.webp")}
                      alt="image"
                    />
                    <div className="box-title">
                      <p>
                        Leadersship
                        <br />
                        <span>
                          (Executive program, Change/Crisis management, Business
                          planning)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <LazyLoadImage
                      className="box-icon"
                      src={require("../../assets/images/Members/Frame-8502.webp")}
                      alt="image"
                    />
                    <div className="box-title">
                      <p>
                        Management
                        <br />
                        <span>
                          (Project planning and monitoring, Scrum Master, PMP
                          People management, Customer relationship)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <LazyLoadImage
                      className="box-icon"
                      src={require("../../assets/images/Members/Frame.webp")}
                      alt="image"
                    />
                    <div className="box-title">
                      <p>
                        Technical Skills
                        <br />
                        <span>
                          (Mobile, Full-stack, Data Analytics,
                          Telecom/Networking, Cloud, Test Automation, DevOps)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <LazyLoadImage
                      className="box-icon"
                      src={require("../../assets/images/Members/Group-8505.webp")}
                      alt="image"
                    />
                    <div className="box-title">
                      <p>
                        Soft Skills <br />
                        <span>
                          (Presentation, Assertiveness, Task management,
                          Languages)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <div>
                      <LazyLoadImage
                        className="box-icon"
                        src={require("../../assets/images/Members/Frame-8503.webp")}
                        alt="image"
                      />
                    </div>

                    <div className="box-title">
                      <p>
                        Quality Process <br></br>
                        <span>
                          (Agile, ISO, CMMi, Peer review, Unit test, Design,
                          Coding, Testing){" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-content col-6">
                  <div className="box">
                    <LazyLoadImage
                      className="box-icon"
                      src={require("../../assets/images/Members/Frame-8501.webp")}
                      alt="image"
                    />
                    <div className="box-title">
                      <p>
                        Foundation Training
                        <br />
                        <span>
                          (Executive program, Change/Crisis management, Business
                          planning)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-image-mobile">
              <div className="content-mobile">
                <div className="box-left">
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="content-icon">
                        <LazyLoadImage
                          className="box-icon"
                          src={require("../../assets/images/Members/Vector.webp")}
                          alt="image"
                        />
                      </div>
                      <div className="box-title">
                        <p>
                          <span> University Program with: </span>
                          <br />
                          65 universities
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="content-icon">
                        <LazyLoadImage
                          className="box-icon"
                          src={require("../../assets/images/Members/Vector-1.webp")}
                          alt="image"
                        />
                      </div>
                      <div className="box-title">
                        <p>
                          {language === "vi" ? "10.000+" : "10,000+"}
                          <br />
                          <span>resumes/year </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="content-icon">
                        <LazyLoadImage
                          className="box-icon"
                          src={require("../../assets/images/Members/Vector-2.webp")}
                          alt="image"
                        />
                      </div>
                      <div className="box-title">
                        <p>
                        {language === "vi" ? "1.000+" : "1,000+"} <span>interns/year </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="content-icon">
                        <LazyLoadImage
                          className="box-icon"
                          src={require("../../assets/images/Members/Vector-3.webp")}
                          alt="image"
                        />
                      </div>
                      <div className="box-title">
                        <p>
                          100+ <span>courses/year </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-right">
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Frame-8504.webp")}
                            alt="image"
                          />
                          <p className="line-title">Leadership</p>
                        </div>
                        <p className="line-text">
                          (Executive program, Change/Crisis management, Business
                          planning)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Frame-8502.webp")}
                            alt="image"
                          />
                          <p className="line-title">Management</p>
                        </div>
                        <p className="line-text">
                          (Project planning and monitoring, Scrum Master, PMP
                          People management, Customer relationship)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Frame.webp")}
                            alt="image"
                          />

                          <p className="line-title"> Technical Skills</p>
                        </div>
                        <p className="line-text">
                          (Mobile, Full-stack, Data Analytics,
                          Telecom/Networking, Cloud, Test Automation, DevOps)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Group-8505.webp")}
                            alt="image"
                          />
                          <p className="line-title"> Soft Skills</p>
                        </div>
                        <p className="line-text">
                          (Presentation, Assertiveness, Task management,
                          Languages)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Frame-8503.webp")}
                            alt="image"
                          />
                          <p className="line-title"> Quality Process </p>
                        </div>
                        <p className="line-text">
                          (Agile, ISO, CMMi, Peer review, Unit test, Design,
                          Coding, Testing){" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-content col-6">
                    <div className="box">
                      <div className="box-title">
                        <div>
                          <LazyLoadImage
                            className="box-icon"
                            src={require("../../assets/images/Members/Frame-8501.webp")}
                            alt="image"
                          />

                          <p className="line-title"> Foundation Training</p>
                        </div>
                        <p className="line-text">
                          (Executive program, Change/Crisis management, Business
                          planning)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeTalentPool;
