import React from "react";
import { useTranslation } from "react-i18next";
// import background from "../assets/images/AboutUs/background-about.webp"
import "../../assets/sass/membersTwo.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import data from "../../config/config";

const MembersTwo = () => {
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div className="d-flex members-two">
      <div className="members-two-backgroud">
        <LazyLoadImage
          className="backgroud-image"
          src={require("../../assets/images/AboutUs/backgroud-frame-big.webp")}
          alt="image"
        />
      </div>
      <div className="about-us-content col-lg-7 col-xs-12 membertwo-title">
        <div className="members-two-title">{t("TMA.Solutions")}</div>
        <div className="about-us-span">
          {language === "EN" || language === "en" ? (
            <div>
              Reliable Technology & Innovation Partner for Companies Worldwide
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="members-two-box d-flex">
          <div className="col-6 col-box-left">
            <div className="box-content">
              <div className="box-content-text">
                {t("Telecom.5GAutomotive")}
              </div>
              <div className="membertwo-engineer">
                {t("Telecom.engineer")} {t("engineer")}
              </div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Finance.Insurance")}</div>
              <div className="membertwo-engineer">{data.engineerFinance} {t("engineer")}</div>
            </div>
            <div className="box-content">
              <div
                className={
                  language === "EN" || language === "en"
                    ? "box-content-text e-commerce-retail"
                    : "box-content-text e-commerce-retail-VN"
                }
              >
                {t("E-commerce.Retail")}
              </div>
              <div className="membertwo-engineer">{data.engineerEcommerce} {t("engineer")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("health.Care")}</div>
              <div className="membertwo-engineer">{data.engineerHealthcare} {t("engineer")}</div>
            </div>
          </div>
          <div className="col-6 col-box-right">
            <div className="box-content">
              <div className="box-content-text box-content-app">
                {t("Application.Development")}
              </div>
              <div className="membertwo-engineer">
                {t("Application.engineer")} {t("engineer")}
              </div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Software.Testing")}</div>
              <div className="membertwo-engineer">{data.engineerTesting} {t("engineer")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">Data Analytics & AI/ML</div>
              <div className="membertwo-engineer">{data.engineerDataAnalytics} {t("engineer")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">IoT & Robotics</div>
              <div className="membertwo-engineer">{data.engineerIOT} {t("engineer")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 col-xs-12 col-right-page membertwo-bottom">
        <div className="members-us-img">
          <div className="about-us-backroud-frame">
            <LazyLoadImage
              src={require("../../assets/images/AboutUs/backgroud-frame.webp")}
              alt="image"
            />
          </div>

          {language === "EN" || language === "en" ? (
            <div className="d-flex member-container-image">
              <div className="image-members-1-top">
                <LazyLoadImage
                  className="image-members-1"
                  src={require("../../assets/images/Members/image-memberstwo-1.webp")}
                  alt="image"
                />
              </div>
              <div className="image-about-us-lab8">
                <LazyLoadImage
                  className="image-members-2"
                  src={require("../../assets/images/Members/image-memberstwo-2.webp")}
                  alt="image"
                />
              </div>
            </div>
          ) : (
            <div className="d-flex member-container-image-vn">
              <div className="image-members-1-top">
                <LazyLoadImage
                  className="image-members-1"
                  src={require("../../assets/images/Members/image-memberstwo-1.webp")}
                  alt="image"
                />
              </div>
              <div className="image-about-us-lab8">
                <LazyLoadImage
                  className="image-members-2"
                  src={require("../../assets/images/Members/image-memberstwo-2.webp")}
                  alt="image"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembersTwo;
