import React from "react";
import "../../assets/sass/membersFour.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MembersFour() {
  return (
    <div className="members-four">
      <div className="members-four-backgroud">
        <div className="members-four-contain container">
          <div className="title">TMA Solutions</div>
          <div className="title-span">Đối tác công nghệ tin cậy của bạn</div>
          <div className="content">
            <div className="d-flex content-member-four">
              <div className="content-letf content-item ">
                <div className="content-icon ">
                  <LazyLoadImage
                    className="memberfour-icon"
                    src={require("../../assets/images/Members/icon-software-outsourcing-enterprises.webp")}
                    alt="image"
                  />
                </div>
                <div className="content-title">Top 10</div>
                <div className="content-text">
                  Doanh nghiệp Xuất khẩu <br /> phần mềm và dịch vụ CNTT
                </div>
              </div>
              <div className="hr hr1" />
              <div className="content-betwen content-item">
                <div className="content-icon">
                  <LazyLoadImage
                    className="memberfour-icon"
                    src={require("../../assets/images/Members/icon-AI-IoT.webp")}
                    alt="image"
                  />
                </div>
                <div className="content-title">Top 10</div>
                <div className="content-text">
                  Doanh nghiệp cung cấp giải pháp <br /> Thành phố thông minh
                </div>
              </div>
              <div className="hr hr2" />
              <div className="content-betwen  content-item">
                <div className="content-icon">
                  <LazyLoadImage
                    className="memberfour-icon"
                    src={require("../../assets/images/Members/icon-health-tech.webp")}
                    alt="image"
                  />
                </div>
                <div className="content-title">Top 10</div>
                <div className="content-text">Doanh nghiệp HealthTech</div>
              </div>
              <div className="hr" />
              <div className="content-right content-item">
                <div className="content-icon">
                  <LazyLoadImage
                    className="memberfour-icon"
                    src={require("../../assets/images/Members/icon-fintech.webp")}
                    alt="image"
                  />
                </div>
                <div className="content-text">
                  Doanh nghiệp Công nghệ số <br /> Nghìn tỷ Việt Nam 2023
                </div>
              </div>
            </div>
          </div>
          <div className="image">
            <div className="d-flex image-memberfour">
              <div className="col-xl-6 image-col-lab-6">
                <LazyLoadImage
                  className="image-lab-6"
                  src={require("../../assets/images/Members/image-lab-6.webp")}
                  alt="image"
                />
              </div>
              <div className="col-xl-6 image-col-lab-8">
                <LazyLoadImage
                  className="image-lab-8"
                  src={require("../../assets/images/Members/image-lab-8.webp")}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembersFour;
