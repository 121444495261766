import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "../assets/sass/Infographics.scss";

export default function Tma25Years() {
  const i18nextLng =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  return (
    <section className="tma_25_years">
      <div className="container">
        <div className="cf-title-12">
          <h3 className="title">TMA 25 Years infographic</h3>
        </div>
        {i18nextLng === "EN" ||
        i18nextLng === "en" ||
        i18nextLng === "en-US" ? (
          <>
            <LazyLoadImage
              className="TMA-25-years-infographics"
              src={require("../assets/images/tma-25-years/TMA-25-years-infographics.webp")}
              alt="image"
            />
          </>
        ) : (
          <>
            <LazyLoadImage
              className="TMA-25-years-infographics"
              src={require("../assets/images/tma-25-years/TMA-25-years-infographics-VN.webp")}
              alt="image"
            />
          </>
        )}
      </div>
    </section>
  );
}
