import { useLocation } from "react-router-dom";

// custom hook to get the current pathname in React
const listPageURLEN = [
  "/",
  "/about-us",
  "/history",
  "/technologies",
  "/solutions",
  "/members",
  "/university-cooperative",
  "/media",
  "/contact-us",
];
const listPageURLVN = [
  "/",
  "/ve-chung-toi",
  "/lich-su-hinh-thanh",
  "/cong-nghe",
  "/giai-phap",
  "/thanh-vien",
  "/hop-tac-dai-hoc",
  "/thu-vien-hinh-anh",
  "/lien-he",
];
const usePathname = () => {
  const location = useLocation();
  var pathname = location.pathname;
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  if (language === "EN" || language === "en" || language === "en-US") {
    if (listPageURLVN.indexOf(pathname) !== -1)
      pathname = listPageURLEN[listPageURLVN.indexOf(pathname)];
  } else {
    if (listPageURLEN.indexOf(pathname) !== -1)
      pathname = listPageURLVN[listPageURLEN.indexOf(pathname)];
  }
  return pathname;
};
export default usePathname;
