import React from "react";
import "../../assets/sass/benefitsForSetting.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BenefitsForSetting() {
  return (
    <div className="benefits-for-setting">
      <div className="strong-quality-foundation-backgroud">
        <div className="strong-quality-foundation-contain container">
          <div className="title">
            <div>Benefits for setting up R&D Center</div>
            <div> in TMA Innovation Park </div>
          </div>
          <div className="content">
            <div className="d-flex content-row">
              <div className="col-xl-6 content-col-left">
                <div className="d-flex content-left-row">
                  <div className="col-xl-6 col-left">
                    <div className="box">
                      <div className="box-backgroud box-1">
                        Science and education center in central Vietnam
                      </div>
                    </div>
                    <div className="box">
                      <div className="box-backgroud">Low operation cost</div>
                    </div>
                    <div className="box">
                      <div className="box-backgroud">Green environment</div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-right">
                    <div className="box">
                      <div className="box-backgroud">
                        <div>
                          <div>Available modern facility</div>
                          <div>
                            <LazyLoadImage
                              className="icon-arrow"
                              src={require("../../assets/images/Members/icon-arrow-right.webp")}
                              alt="image"
                            />
                            <span className="box-text">Quick setup time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="box-backgroud">
                        <div>
                          <div>Leading brand</div>
                          <div>
                            <LazyLoadImage
                              className="icon-arrow"
                              src={require("../../assets/images/Members/icon-arrow-right.webp")}
                              alt="image"
                            />
                            <span className="box-text">
                              Attract top talents
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="box-backgroud">
                        <div>
                          <div>Training center</div>
                          <div>
                            <LazyLoadImage
                              className="icon-arrow"
                              src={require("../../assets/images/Members/icon-arrow-right.webp")}
                              alt="image"
                            />
                            <span className="box-text">
                              Assure qualified resources
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box-bottom">
                    <div className="box-backgroud-bottom">
                      <LazyLoadImage
                        className="icon-union"
                        src={require("../../assets/images/Members/icon-union.webp")}
                        alt="image"
                      />
                      <span className="box-bottom-text">
                        Your long-term R&D center
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 content-col-right">
                <div className="d-flex">
                  <div className="col-xl-6 col-image-1">
                    <LazyLoadImage
                      className="image-1"
                      src={require("../../assets/images/Members/benefits-for-setting-image-1.webp")}
                      alt="image"
                    />
                  </div>
                  <div className="col-xl-6 col-image-2">
                    <LazyLoadImage
                      className="image-2"
                      src={require("../../assets/images/Members/benefits-for-setting-image-2.webp")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitsForSetting;
