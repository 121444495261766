import React from "react";
import "../../assets/sass/TMAInnovationPark.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function TMAInnovationPark() {
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  return (
    <div className="TMA-innovation-park">
      <div className="TMA-innovation-park-backgroud">
        <div className="TMA-innovation-park-contain container">
          {language === "EN" || language === "en" || language === "en-US" ? (
            <div className="title">TMA Innovation Park</div>
          ) : (
            <div className="title-VN">
              Trung tâm Công nghệ hàng đầu tại Miền Trung
            </div>
          )}
          {language === "EN" || language === "en" || language === "en-US" ? (
            <div className="title-span">International working environment</div>
          ) : (
            ""
          )}
          <div
            className={`content ${
              language === "EN" || language === "en" || language === "en-US"
                ? ""
                : "none"
            } `}
          >
            <div className="d-flex">
              <div className="d-flex content-letf">
                <div className="content-icon">
                  <LazyLoadImage
                    src={require("../../assets/images/Members/icon-TMAInnovationPark-img-1.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-text">Location for</div>
                  <div className="content-title">Software Centers</div>
                </div>
              </div>
              <div className="d-flex content-betwen">
                <div className="content-icon">
                  <LazyLoadImage
                    src={require("../../assets/images/Members/icon-TMAInnovationPark-img-2.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-text">R&D Centers for</div>
                  <div className="content-title">High Tech Products</div>
                </div>
              </div>
              <div className="d-flex content-right">
                <div className="content-icon">
                  <LazyLoadImage
                    src={require("../../assets/images/Members/icon-TMAInnovationPark-img-3.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-text">Attracting Talents in</div>
                  <div className="content-title"> Central Vietnam</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`content-VN ${
              language === "VI" || language === "vi" || language === "vi-VN"
                ? ""
                : "none"
            } `}
          >
            <div className="d-flex">
              <div className="col-xl-4 box-col-1">
                <div className="box-backgroud box-1">
                  <div className="box-text">
                    Nơi đặt các trung tâm phần mềm – R&D của các công ty công
                    nghệ trong và ngoài nước
                  </div>
                </div>
              </div>
              <div className="col-xl-4 box-col-2">
                <div className="box-backgroud box-2">
                  <div className="box-text">
                    Các trung tâm nghiên cứu và thiết kế sản phẩm “Made in
                    Vietnam”
                  </div>
                </div>
              </div>
              <div className="col-xl-4 box-col-3">
                <div className="box-backgroud">
                  <div className="box-text">
                    Hội tụ nhân tài trong và ngoài nước
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              language === "EN" || language === "en" || language === "en-US"
                ? "image"
                : "image-VN"
            }
          >
            <div className="d-flex">
              <div className="col-xl-4 image-col-1">
                <LazyLoadImage
                  className="image-inno-park"
                  src={require("../../assets/images/Members/image-1-TMAInnovationPark.webp")}
                  alt="image"
                />
              </div>
              <div className="col-xl-4 image-col-2">
                <LazyLoadImage
                  className="image-inno-park"
                  src={require("../../assets/images/Members/image-2-TMAInnovationPark.webp")}
                  alt="image"
                />
              </div>
              <div className="col-xl-4 image-col-3">
                <LazyLoadImage
                  className="image-inno-park"
                  src={require("../../assets/images/Members/image-3-TMAInnovationPark.webp")}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TMAInnovationPark;
