import React from "react";
import { Image } from "react-bootstrap";
import "../assets/sass/Banner.scss";
// import background from "../assets/images/banner/backgound-banner.webp"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = () => {
  const { t } = useTranslation();
  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  return (
    <div className="contain-banner">
      <div className="contain-banner-backgound">
        <div className="container banner_form">
          <div className="banner-logo-tma">
            <LazyLoadImage
              className="banner-logo-tma-img"
              src={require("../assets/images/banner/logo-TMA-Technology-group.webp")}
              alt="image"
            />
          </div>
          <div className="banner-title">
            <p className="title-banner">
              TMA<span className="title-banner-tma"> Technology Group</span>{" "}
            </p>
          </div>
          <div className="banner-title-nav">
            <span> {t("Your.Technology.Innovation.Partner")} </span>
          </div>
          {/* <div className="banner-contain-logo-three">
                  <div className="banner-logo-three d-flex margin-left--5 margin-right-5">
                    <Col className="col-lg-2 banner-bg padding-right-0 padding-left-0 banner-bg-left">
                     <LazyLoadImage className="banner-bg-left " src={require("../assets/images/banner/banner-bg-left.webp")}  alt="image" />
                    </Col>
                    <Col className="col-lg-8 logo-tma-three padding-right-0 padding-left-0 container-logo">
                      <div className="group-logo-banner d-flex justify-content-between">
                        <div className="group-logo-banner-bot">
                         <LazyLoadImage className="logo-banner-1 logo-tma-three-nav logo-1 " src={require("../assets/images/banner/TMA-favicon.png")} alt="image"  />
                         <LazyLoadImage className="col-xs-6 logo-tma-three-nav logo-2" src={require("../assets/images/banner/tma-binhdinh.webp")} alt="image" />
                         <LazyLoadImage className="col-xs-6 logo-tma-three-nav logo-3 " src={require("../assets/images/banner/tma-innovation.webp")} alt="image" /> 
                        </div>  
                      </div>    
                    </Col>
                    <Col className="col-lg-2 banner-bg banner-bg-right padding-right-0 padding-left-0">
                     <LazyLoadImage className="banner-bg-right " src={require("../assets/images/banner/banner-bg-right.webp")} alt="image" />
                    </Col>
                  </div>
                </div> */}
          <div className="banner-logo">
            <div className="banner-logo-backgroud">
              <div className="backgroud-fame-left">
                <LazyLoadImage
                  className="backgroud-fame-left-img"
                  src={require("../assets/images/banner/banner-bg-left.webp")}
                  alt="image"
                />
                <LazyLoadImage
                  className="backgroud-fame-left-img-mobile"
                  src={require("../assets/images/banner/banner-bg-left-mobile.webp")}
                  alt="image"
                />
              </div>
              <div className="backgroud-fame-right">
                <LazyLoadImage
                  className="backgroud-fame-right-img"
                  src={require("../assets/images/banner/banner-bg-right.webp")}
                  alt="image"
                />
                <LazyLoadImage
                  className="backgroud-fame-right-img-mobile"
                  src={require("../assets/images/banner/banner-bg-right-mobile.webp")}
                  alt="image"
                />
              </div>
              {language === "en" ||
              language === "EN" ||
              language === "en-US" ? (
                <>
                  <div className="logo-tma">
                    <a
                      href="https://www.tmasolutions.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <LazyLoadImage
                        className="banner-logo-img"
                        src={require("../assets/images/banner/logo-tma-x4.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                  <div className="logo-tma-BD">
                    <a
                      href="https://www.tma-binhdinh.vn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LazyLoadImage
                        className="banner-logo-img-bd"
                        src={require("../assets/images/banner/Logo-TMA-BinhDinh-green-PNG.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                  <div className="logo-tma-inno">
                    <a
                      href="https://www.tmainnovation.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="banner-logo-img-inno"
                        src={require("../assets/images/banner/logo-tma-inno-x4.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="logo-tma">
                    <a
                      href="https://www.tmasolutions.vn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <LazyLoadImage
                        className="banner-logo-img"
                        src={require("../assets/images/banner/logo-tma-x4.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                  <div className="logo-tma-BD">
                    <a
                      href="https://www.tma-binhdinh.vn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="banner-logo-img-bd"
                        src={require("../assets/images/banner/Logo-TMA-BinhDinh-green-PNG.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                  <div className="logo-tma-inno">
                    <a
                      href="https://www.tmainnovation.vn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LazyLoadImage
                        className="banner-logo-img-inno"
                        src={require("../assets/images/banner/logo-tma-inno-x4.webp")}
                        alt="image"
                      />
                    </a>
                  </div>
                </>
              )}
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
