import React from "react";
import "../../assets/sass/experiencedInLarge.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ExperiencedInLarge() {
  return (
    <div className="d-flex experienced-in-large">
      <div className="experienced-in-large-backgroud">
        <LazyLoadImage
          className="backgroud-image"
          src={require("../../assets/images/AboutUs/backgroud-frame-big.webp")}
          alt="image"
        />
      </div>
      <div className="experienced-in-large-content col-lg-6 col-xs-12">
        <div className="members-two-title">
          <div>Experienced In</div>
          <div>Large & Complex Projects</div>
        </div>
        <div className="members-two-box d-flex">
          <div className="col-6 col-box-left">
            <LazyLoadImage
              className="image-members-4"
              src={require("../../assets/images/Members/image-experienced-1.webp")}
              alt="image"
            />
          </div>
          <div className="col-6 col-box-right">
            <LazyLoadImage
              className="image-members-5"
              src={require("../../assets/images/Members/image-experienced-5.webp")}
              alt="image"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-xs-12 experienced-in-large-right">
        <div className="members-us-img">
          <div className="about-us-backroud-frame">
            <LazyLoadImage
              src={require("../../assets/images/AboutUs/backgroud-frame.webp")}
              alt="image"
            />
          </div>
          <div className="d-flex">
            <div className="image-members-1-top">
              <div className="d-flex">
                <div className="col-xl-7 image-1">
                  <LazyLoadImage
                    className="image-members-1"
                    src={require("../../assets/images/Members/image-experienced-4.webp")}
                    alt="image"
                  />
                </div>
                <div className="col-xl-5 image-2">
                  <LazyLoadImage
                    className="image-members-2"
                    src={require("../../assets/images/Members/image-experienced-2.webp")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-xl-12 image-bottom">
                <LazyLoadImage
                  className="image-members-3"
                  src={require("../../assets/images/Solutions/image4-solutions.webp")}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperiencedInLarge;
