import React from "react";
import "../../assets/sass/universityCooperation.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function UniversityCooperation() {
  return (
    <div className="university-cooperation">
      <div className="university-cooperation-backgroud">
        <div className="university-cooperation-contain container">
          <div className="title">Hợp tác đại học</div>
          <div className="content university-content">
            <div className="d-flex university-content-flex ">
              <div className="d-flex content-letf">
                <div className="content-icon">
                  <LazyLoadImage
                    className="image-university"
                    src={require("../../assets/images/Members/icon-software-outsourcing-enterprises-univer.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-title">65 đại học</div>
                  <div className="content-text">trong & ngoài nước</div>
                </div>
              </div>
              <div className="d-flex content-betwen">
                <div className="content-icon">
                  <LazyLoadImage
                    className="image-university"
                    src={require("../../assets/images/Members/icon-univer.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-title">1.000+ sinh viên</div>
                  <div className="content-text">thực tập/năm</div>
                </div>
              </div>
              <div className="d-flex content-right">
                <div className="content-icon">
                  <LazyLoadImage
                    className="image-university"
                    src={require("../../assets/images/Members/icon-univer.webp")}
                    alt="image"
                  />
                </div>
                <div className="contain-content">
                  <div className="content-title">Sinh viên thực tập từ</div>
                  <div className="content-text">
                    Mỹ, Canada, Úc, Singapore, Nhật Bản, Châu Âu
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-image">
            <div className="d-flex content-image-bottom ">
              <div className="col-xl-5 col-box col-box-bottom">
                <div className="box-content">
                  <div className="box d-flex content-box">
                    <ul>
                      <li className="box-li">Kiến tập</li>
                      <li className="box-li">Thực tập (sinh viên)</li>
                      <li className="box-li">Hội thảo công nghệ</li>
                      <li className="box-li">Góp ý chương trình đào tạo</li>
                      <li>Sản phẩm công nghệ</li>
                    </ul>
                    <ul>
                      <li className="box-li">Tuyển dụng</li>
                      <li className="box-li">Thực tập (giảng viên)</li>
                      <li className="box-li">Hướng dẫn đồ án + luận văn</li>
                      <li>Nghiên cứu khoa học</li>
                    </ul>
                  </div>
                </div>
                <div className="box-content-2">
                  <div className="box d-flex content-box">
                    <ul>
                      <li className="box-li">Kiến tập</li>
                      <li className="box-li">Thực tập (sinh viên)</li>
                      <li className="box-li">Hội thảo công nghệ</li>
                    </ul>
                    <ul>
                      <li className="box-li">Góp ý chương trình đào tạo</li>
                      <li className="box-li">Sản phẩm công nghệ</li>
                      <li className="box-li">Tuyển dụng</li>
                    </ul>
                    <ul>
                      <li className="box-li">Thực tập (giảng viên)</li>
                      <li className="box-li">Hướng dẫn đồ án + luận văn</li>
                      <li>Nghiên cứu khoa học</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-box-right d-flex">
                <div className="image-right-1">
                  <LazyLoadImage
                    className="image"
                    src={require("../../assets/images/Members/university-image-1.webp")}
                    alt="image"
                  />
                </div>
                <div className="image-right-2">
                  <LazyLoadImage
                    className="image"
                    src={require("../../assets/images/Members/university-image-2.webp")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniversityCooperation;
