import React, { useState } from "react";
import Home from "./components/Home";
import Header from "./components/Header";
import "./App.css";
import "../src/assets/images/AboutUs/backgroud-frame-big.webp";
import HeaderMembers from "./components/MenuMembers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Routes, Route} from "react-router-dom";
// import AboutUs from "./components/AboutUs";

import Tma25Years from "./components/tma-25-years";

function App() {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [classPage, setClassPage] = useState("");
  const [activeSlider, setActiveSlider] = useState(0);

  const path = window.location.href;

  const callbackActive = (data) => {
    setActiveSlider(data);
  };

  const callbackFunction = (childData) => {
    setMessage(childData);
  };
  const callbackclassPage = (data) => {
    setClassPage(data);
  };
  const dataStatus = (data) => {
    setStatus(data);
  };

  return (
    <div className="App">
      <div
        className={
          classPage === "about-us" ? "backgroud-about-us" : "backgroud-none"
        }
      >
        <LazyLoadImage
          className="backgroud-image-1"
          src={require("../src/assets/images/AboutUs/backgroud-frame-overview.webp")}
          alt="image"
        />
      </div>
      <div
        className={
          classPage === "technologies" ||
          classPage === "membersTwo" ||
          classPage === "experiencedInLarge" ||
          classPage === "TMASolutionsBinhDinh" ||
          classPage === "TMAInnovation" ||
          classPage === "TMAInnovationVN" ||
          classPage === "dataScienceLab" ||
          classPage === "researchCenter"
            ? "backgroud-about-us"
            : "backgroud-none"
        }
      >
        <LazyLoadImage
          className="backgroud-image-1"
          src={require("../src/assets/images/AboutUs/backgroud-frame-big.webp")}
          alt="image"
        />
      </div>
      <div
        className={
          classPage === "membersOne" ? "backgroud-members" : "backgroud-none"
        }
      >
        <LazyLoadImage
          className="backgroud-image-1"
          src={require("../src/assets/images/Members/backgroud-members-one-frame.webp")}
          alt="image"
        />
      </div>
      <Header
        dataUrl={message}
        status={status}
        classPage={classPage}
        activeSlider={activeSlider}
        callbackActive={callbackActive}
      />

      {(path.includes("/members") && classPage !== "membersOne") ||
      (path.includes("/thanh-vien") && classPage !== "membersOne") ? (
        <HeaderMembers
          dataUrl={message}
          classPage={classPage}
          status={status}
          activeSlider={activeSlider}
          handleClickActive={callbackActive}
        />
      ) : (
        ""
      )}

      <Routes>
        <Route
          path="*"
          element={
            <Home
              parentCallback={callbackFunction}
              classPage={callbackclassPage}
              StatusCall={dataStatus}
              callbackActive={callbackActive}
              activeSlider={activeSlider}
            />
          }
        />

        <Route path="/tma-25-years-infographic" element={<Tma25Years />} />
      </Routes>
      {/* <Home
        parentCallback={callbackFunction}
        classPage={callbackclassPage}
        StatusCall={dataStatus}
        callbackActive={callbackActive}
        activeSlider={activeSlider}
      /> */}
    </div>
  );
}

export default App;
