import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import '../assets/sass/header.scss';
import ChangeLanguage from '../components/ChangeLanguage';
import ChangeLanguageMobile from './ChangeLanguageMobile';
// import useLocation from '../hooks/useLocation';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const RenderMenu = props => {
	const i18nextLng =
		localStorage.getItem('lang') === null ? localStorage.getItem('i18nextLng') : localStorage.getItem('lang');
	const { t } = useTranslation();
	const Location = useLocation();
	const statusActive = props.status;

	const url = props.url;
	const activeURL = statusActive === undefined ? Location.pathname : url;

	const menu = [
		{
			id: 1,
			href: '/',
			active: 0,
			label: t('home')
		},
		{
			id: 2,
			href: '/about-us',
			active: 1,
			label: t('about.us')
		},
		{
			id: 3,
			href: '/history',
			active: 3,
			label: t('menu.History')
		},
		{
			id: 4,
			href: '/technologies',
			active: 5,
			label: t('Technologies')
		},
		{
			id: 5,
			href: '/solutions',
			active: 6,
			label: t('Solutions')
		},
		{
			id: 6,
			href: '/members',
			active: 10,
			label: t('Members')
		},
		{
			id: 7,
			href: '/media',
			active: 21,
			label: t('menu.media.awards')
		},

		{
			id: 8,
			href: '/contact-us',
			active: 22,
			label: t('menu.Contact')
		}
	];

	const menuVN = [
		{
			id: 1,
			href: '/',
			active: 0,

			label: t('home')
		},
		{
			id: 2,
			href: '/ve-chung-toi',
			active: 1,

			label: t('about.us')
		},
		{
			id: 3,
			href: '/lich-su-hinh-thanh',
			active: 3,

			label: t('menu.History')
		},
		{
			id: 4,
			href: '/cong-nghe',
			label: t('Technologies'),
			active: 5
		},
		{
			id: 5,
			href: '/giai-phap',
			label: t('Solutions'),
			active: 6
		},
		{
			id: 6,
			href: '/thanh-vien',
			label: t('Members'),
			active: 10
		},
		{
			id: 7,
			href: '/hop-tac-dai-hoc',
			label: t('cooperative.university'),
			active: 22
		},
		{
			id: 8,
			href: '/thu-vien-hinh-anh',
			label: t('menu.media.awards'),
			active: 23
		},

		{
			id: 9,
			href: '/lien-he',
			label: t('menu.Contact'),
			active: 24
		}
	];

	function scrolldiv(item) {
		var elem = document.getElementById(item);

		switch (item) {
			case '/media':
				var headerOffset = 50;
				break;
			case '/solutions':
				headerOffset = 120;
				break;
			case '/giai-phap':
				headerOffset = 120;
				break;
			case '/contact-us':
				headerOffset = 10;
				break;
			case '/technologies':
				headerOffset = 50;
				break;

			default:
				headerOffset = 90;

				break;
		}

		var elementPosition = elem.getBoundingClientRect().top;
		var offsetPosition = elementPosition - headerOffset;

		if (elem) {
			window.scrollBy({
				top: offsetPosition,
				behavior: 'smooth'
			});
		}
	}

	return (i18nextLng === 'en' || i18nextLng === 'EN' || i18nextLng === 'en-US' ? menu : menuVN).map(item => {
		return (
			<Row key={item.id}>
				<Col className="menu-tag-a">
					<div className="padding-left-16 desktop-menu ">
						<Link
							to={item.href}
							onClick={() => props.callbackActive(item.active)}
							className={`${activeURL === item.href ? 'active-tab menu-tag-a' : 'menu-tag-a'}`}
						>
							{item.label}
						</Link>
					</div>
					<div className="padding-left-16 mobile-menu" onClick={() => scrolldiv(item.href)}>
						<Link
							to={item.href}
							className={`${activeURL === item.href ? 'active-tab menu-tag-a' : 'menu-tag-a'}`}
							onClick={props.handleToggleBar}
						>
							{item.label}
						</Link>
					</div>
				</Col>
			</Row>
		);
	});
};

const Header = props => {
	const [showNav, setShowNav] = useState(false);

	window.onscroll = function () {
		scrollFunction();
	};
	function scrollFunction() {
		if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
			document.getElementById('navbar').style.top = '0';
		} else {
			document.getElementById('navbar').style.top = '0';
		}
	}
	const handleToggleBar = () => {
		setShowNav(!showNav);
	};

	// const [setLang] = useState(''),
	// 	callbackFunctionLang = lang => {
	// 		setLang(lang);
	// 	};

	return (
		<div className={scrollFunction ? 'menu-scroll' : ''} id="navbar">
			<ChangeLanguage
				// Callback={callbackFunctionLang}
				dataUrl={props.dataUrl}
			/>
			<div
				className={
					(props.dataUrl === '/members' && props.classPage !== 'membersOne') ||
					(props.dataUrl === '/thanh-vien' && props.classPage !== 'membersOne')
						? 'container-menu menu-member'
						: 'container-menu'
				}
			>
				<div className="d-flex menu padding-top-12 padding-bottom-20">
					<div className="Logo col-1 padding-left-0 padding-right-0">
						<a href="/">
							<LazyLoadImage src={require('../assets/images/Logo/logo-TMA.webp')} alt="logo" className="logo-tma" />
							{/*<LazyLoadImage src={require("../assets/images/Logo/logoT-M-A.webp")} alt="hình ảnh" width="100px" height="46px"/> */}
						</a>
					</div>
					<div className={`title-menu col-11 d-flex ${showNav ? 'active' : ''}`}>
						<RenderMenu
							url={props.dataUrl}
							handleToggleBar={handleToggleBar}
							status={props.status}
							callbackActive={props.callbackActive}
							classPage={props.classPage}
							activeSlider={props.activeSlider}
						/>
					</div>
					<ChangeLanguageMobile
						// Callback={callbackFunctionLang}
						dataUrl={props.dataUrl}
					/>
					<div className="wrapper-menu__bar" onClick={handleToggleBar}>
						<div className="wrapper-menu__bar__item"></div>
						<div className="wrapper-menu__bar__item"></div>
						<div className="wrapper-menu__bar__item"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
