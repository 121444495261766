import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/sass/menuMembers.scss";

const MenuMember = (props) => {
  const i18nextLng =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");

  const menu = [
    {
      id: 1,
      href: "/members",
      hrefMobile: "#tma-solution",
      idMobile: "tma-solution",
      active: 11,
      statusActive: [11, 12, 13, 14, 15, 16],
      label: "TMA Solutions",
    },
    {
      id: 2,
      href: "/members",
      hrefMobile: "#tma-solution-binh-dinh",
      idMobile: "tma-solution-binh-dinh",
      active: 17,
      statusActive: [17, 18, 19],
      label: "TMA Solutions Binh Dinh",
    },
    {
      id: 3,
      active: 20,
      href: "/members",
      hrefMobile: "#tma-innovation",
      idMobile: "tma-innovation",
      statusActive: [20],
      label: "TMA Innovation",
    },
  ];
  const menuVN = [
    {
      id: 1,
      href: "/thanh-vien",
      active: 11,
      statusActive: [11, 12, 13, 14, 15],
      label: "TMA Solutions",
      hrefMobile: "#tma-solution",
      idMobile: "tma-solution",
    },
    {
      id: 2,
      href: "/thanh-vien",
      active: 16,
      statusActive: [16, 17, 18, 19],
      label: "TMA Solutions Bình Định",
      hrefMobile: "#tma-solution-binh-dinh",
      idMobile: "tma-solution-binh-dinh",
    },
    {
      id: 3,
      href: "/thanh-vien",
      active: 20,
      statusActive: [20, 21],
      label: "TMA Innovation",
      hrefMobile: "#tma-innovation",
      idMobile: "tma-innovation",
    },
  ];

  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );

    var headerOffset = 250;
    var elementPosition = target.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  if (i18nextLng === "en" || i18nextLng === "EN" || i18nextLng === "en-US") {
    return menu.map((item) => {
      return (
        <Row key={item.id}>
          <Col className="menu-tag-a">
            <div className="padding-left-16 desktop-menu">
              <Link
                to={item.hrefMobile}
                onClick={() => props.handleClickActive(item.active)}
                data-to-scrollspy-id={item.idMobile}
                className={`${
                  item.statusActive.includes(props.activeSlider)
                    ? "active-tab menu-tag-a"
                    : "menu-tag-a"
                }`}
              >
                {item.label}
              </Link>
            </div>
            <div className="padding-left-16 mobile-menu">
              <Link
                to={item.hrefMobile}
                onClick={(e) => onPress(e)}
                data-to-scrollspy-id={item.idMobile}
                className={"menu-tag-a"}
              >
                {item.label}
              </Link>
            </div>
          </Col>
        </Row>
      );
    });
  } else {
    return menuVN.map((item) => {
      return (
        <Row key={item.id}>
          <Col className="menu-tag-a">
            <div className="padding-left-16 desktop-menu">
              <Link
                to={item.hrefMobile}
                onClick={() => props.handleClickActive(item.active)}
                data-to-scrollspy-id={item.idMobile}
                className={`${
                  item.statusActive.includes(props.activeSlider)
                    ? "active-tab menu-tag-a"
                    : "menu-tag-a"
                }`}
              >
                {item.label}
              </Link>
            </div>
            <div className="padding-left-16 mobile-menu">
              <Link
                to={item.hrefMobile}
                onClick={(e) => onPress(e)}
                data-to-scrollspy-id={item.idMobile}
                className={"menu-tag-a"}
              >
                {item.label}
              </Link>
            </div>
          </Col>
        </Row>
      );
    });
  }
};

const HeaderMembers = (props) => {
  return (
    <div
      className={
        props.classPage === undefined
          ? "menu-scroll-member mobile-menu-member"
          : "deskstop-menu-member"
      }
      id="navbarMembers"
    >
      <div>
        <div className="menu ">
          <div className={`title-menu-member`}>
            <MenuMember
              url={props.dataUrl}
              status={props.status}
              classPage={props.classPage}
              activeSlider={props.activeSlider}
              handleClickActive={props.handleClickActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderMembers;
