import React from "react";
import { useTranslation } from "react-i18next";
// import background from "../assets/images/AboutUs/background-about.webp"
import "../../assets/sass/TMAInnovation.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TMAInnovation = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex tma-innovation">
      <div className="tma-innovation-backgroud">
        <LazyLoadImage
          className="backgroud-image"
          src={require("../../assets/images/AboutUs/backgroud-frame-big.webp")}
          alt="image"
        />
      </div>
      <div className="about-us-content col-lg-6 col-xs-12">
        <div className="members-two-title">{t("TMA Innovation")}</div>
        <div className="about-us-span">
          <div>Innovative tech products and solutions</div>
        </div>
        <div className="members-two-box d-flex">
          <div className="col-6 col-box-left">
            <div className="box-content">
              <div className="box-content-text">{t("Smart building")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Smart store")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">
                {t("Machine optimization")}
              </div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Container tracking")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("5G applications")}</div>
            </div>
          </div>
          <div className="col-6 col-box-right">
            <div className="box-content">
              <div className="box-content-text">
                {t("Remote health monitoring")}
              </div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Smart camera")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Access control")}</div>
            </div>
            <div className="box-content">
              <div className="box-content-text">{t("Robot applications")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-xs-12 right-contain">
        <div className="members-us-img">
          <div className="about-us-backroud-frame">
            <LazyLoadImage
              src={require("../../assets/images/AboutUs/backgroud-frame.webp")}
              alt="image"
            />
          </div>
          <div className="image-members-1-top">
            <div className="item-1 d-flex">
              <div className="box-image box">Corporate incubator</div>
              <LazyLoadImage
                className="image-right-1"
                src={require("../../assets/images/Members/tma-inno-image-1.webp")}
                alt="image"
              />
            </div>
            <div className="item-2 d-flex">
              <div className="box-image">Tech Products & Solutions</div>
              <LazyLoadImage
                className="image-right-1"
                src={require("../../assets/images/Members/tma-inno-image-2.webp")}
                alt="image"
              />
            </div>
            <div className="item-3 d-flex">
              <div className="box-image">R&D</div>
              <LazyLoadImage
                className="image-right-1"
                src={require("../../assets/images/Members/tma-inno-image-3.webp")}
                alt="image"
              />
            </div>
            {/* <LazyLoadImage className="image-members-1" src={require("../../assets/images/Members/image-memberstwo-1.webp")} alt="image"/> */}
          </div>
          <div className="content-box">
            <div className="d-flex">
              <div className="box-1">
                <div className="box-image box">Corporate incubator</div>

                <div>
                  <LazyLoadImage
                    src={require("../../assets/images/Members/TMAInnovationVN-image-1.webp")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="box-2">
                <div className="box-image">Tech Products & Solutions</div>

                <div>
                  <LazyLoadImage
                    src={require("../../assets/images/Members/TMAInnovationVN-image-2.webp")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="box-3">
                <div className="box-image">R&D</div>

                <div>
                  <LazyLoadImage
                    src={require("../../assets/images/Members/TMAInnovationVN-image-3.webp")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TMAInnovation;
