import React from "react";
import "../../assets/sass/technologyProduct.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

function TechnologyProduct() {
  return (
    <div className="technology-product">
      <div className="technology-product-backgroud">
        <div className="technology-product-contain container">
          <div className="title">Sản phẩm công nghệ 4.0</div>
          <div className="content">
            <div className="d-flex content-product">
              <div className="d-flex col-xl-6 col-left">
                <div className="col-xl-6 col-image-1">
                  <LazyLoadImage
                    className="image"
                    src={require("../../assets/images/Members/technology-product-image-1.webp")}
                    alt="image"
                  />
                </div>
                <div className="col-xl-6 col-image-2">
                  <LazyLoadImage
                    className="image"
                    src={require("../../assets/images/Members/technology-product-image-2.webp")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-right">
                <div className="d-flex col-right-content ">
                  <div className="col-xl-4 col-box-1 right-content-box ">
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text">Smart camera</div>
                      </div>
                    </div>
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text box-text-2">
                          Tòa nhà và khu dân cư thông minh
                        </div>
                      </div>
                    </div>
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text">Quản lý container</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-box-2 ">
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text box-text-2">
                          Theo dõi sức khỏe từ xa
                        </div>
                      </div>
                    </div>
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text">Tối ưu máy móc</div>
                      </div>
                    </div>
                    <div className="box-content">
                      <div className="box">
                        <div className="box-text">Ứng dụng robot</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-box-3 col-box-content-3  ">
                    <div className="box-content colbox-content  ">
                      <div className="box  ">
                        <div className="box-text">Smart store</div>
                      </div>
                    </div>
                    <div className="box-content colbox-content colbox-content-2">
                      <div className="box ">
                        <div className="box-text">Kiểm soát ra vào</div>
                      </div>
                    </div>
                    <div className="box-content colbox-content">
                      <div className="box ">
                        <div className="box-text">Ứng dụng 5G</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechnologyProduct;
