import React from "react";
import "../assets/sass/mediaAwards.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../assets/images/MediaAwards/image1.webp";
import image2 from "../assets/images/MediaAwards/image2.webp";
import image3 from "../assets/images/MediaAwards/image3.webp";
import image4 from "../assets/images/MediaAwards/image4.webp";
import image5 from "../assets/images/MediaAwards/image5.webp";
import iconSlider1 from "../assets/images/banner/icon-banner-slider.webp";
import iconSlider2 from "../assets/images/banner/icon-banner-slider-bettwen.webp";
import iconSlider3 from "../assets/images/banner/icon-banner-slider-end.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MediaDataMobile(props) {
  const { ref, settings } = props;
  function openInNewTab(url) {
    window.open(url, "_blank").focus();
  }
  return (
    <div>
      <Slider ref={ref} {...settings} className="slider-mobile">
        {/* slider 1 */}
        <div className="col-12 col-slider-item item-col-3">
          <div className="content-col ">
            <div
              className="content-tag-a"
              onClick={() =>
                openInNewTab(
                  "https://vneconomy.vn/tap-doan-hung-thinh-hop-tac-chien-luoc-cung-cong-ty-tma-solutions.htm"
                )
              }
              //   href={item.link}
            >
              <div className="content-image">
                <LazyLoadImage className="content-image-nav" src={image3} />
              </div>
              <div className="title-content">
                <div className="title-nav">
                  Tập đoàn Hưng Thịnh hợp tác chiến lược cùng công ty TMA...
                </div>
                <div className="content-nav">
                  Tập đoàn Hưng Thịnh vừa ký kết hợp tác chiến lược với Công ty
                  TNHH giải pháp phần mềm Tường Minh (TMA Solutions). Sự kiện
                  đánh dấu mốc quan trọng trong mối...
                </div>
                <div className="website-name">VNECONOMY</div>
              </div>
            </div>
          </div>
          <div className="icon-scroll-media">
            <LazyLoadImage
              src={iconSlider3}
              alt="image"
              height="7px"
              width="50px"
            />
          </div>
        </div>
        {/* slider 2 */}
        <div className="col-12 col-slider-item item-col-2">
          <div className="content-col ">
            <div
              className="content-tag-a"
              onClick={() =>
                openInNewTab(
                  "https://www.tmasolutions.vn/tin-tuc/TMA-25-nam-phat-trien-vung-manh/43901"
                )
              }
              //   href={item.link}
            >
              <div className="content-image">
                <LazyLoadImage className="content-image-nav" src={image4} />
              </div>
              <div className="title-content">
                <div className="title-nav">TMA 25 năm phát triển vững mạnh</div>
                <div className="content-nav">
                  TMA tự hào kỷ niệm 25 năm phát triển liên tục, vượt qua nhiều
                  biến động của thị trường để trở thành tập đoàn công nghệ hàng
                  đầu Việt Nam với hơn 4.000 nhân viên. Hành trình 25 năm qua...
                </div>
                <div className="website-name">TMASOLUTIONS</div>
              </div>
            </div>
          </div>
          <div className="icon-scroll-media">
            <LazyLoadImage
              src={iconSlider1}
              alt="image"
              height="7px"
              width="50px"
            />
          </div>
        </div>
        {/* slider 3 */}
        <div className="col-12 col-slider-item item-col-1">
          <div className="content-col ">
            <div
              className="content-tag-a"
              onClick={() =>
                openInNewTab(
                  "https://www.techtimes.vn/binh-dinh-xay-dung-cong-vien-sang-tao-phan-mem-dau-tien-tong-von-dau-tu-8-trieu-usd/"
                )
              }
              //   href={item.link}
            >
              <div className="content-image">
                <LazyLoadImage className="content-image-nav" src={image1} />
              </div>
              <div className="title-content">
                <div className="title-nav">
                  Xây dựng Công viên sáng tạo phần mềm tạo bước đột phá...
                </div>
                <div className="content-nav">
                  Công viên sáng tạo TMA có tổng vốn đầu tư khoảng 8 triệu USD,
                  được chia làm 2 giai đoạn, dự kiến đi vào hoạt động sau 3 năm
                  xây dựng. Sau 15 năm, công viên sẽ có...
                </div>
                <div className="website-name">TECHTIMES</div>
              </div>
            </div>
          </div>
          <div className="icon-scroll-media">
            <LazyLoadImage
              src={iconSlider2}
              alt="image"
              height="7px"
              width="50px"
            />
          </div>
        </div>
        {/* slider 4 */}
        <div className="col-12 col-slider-item item-col-2">
          <div className="content-col ">
            <div
              className="content-tag-a"
              onClick={() =>
                openInNewTab(
                  "http://www.congnghieptieudung.vn/xay-dung-cong-vien-sang-tao-phan-mem-tao-buoc-dot-pha-moi-phat-trien-cong-nghe-cao-tai-binh-dinh-dt12432"
                )
              }
            >
              <div className="content-image">
                <LazyLoadImage className="content-image-nav" src={image2} />
              </div>
              <div className="title-content">
                <div className="title-nav">
                  Bình Định xây dựng công viên sáng tạo phần mềm đầu tiên...
                </div>
                <div className="content-nav">
                  Ngày 28/8, tại Thung lũng sáng tạo Quy Hòa, phường Ghềnh Ráng,
                  TP Quy Nhơn - Công ty TNHH Giải pháp phần mềm Tường Minh Bình
                  Định thuộc Công ty TMA Solutions...
                </div>
                <div className="website-name">CONGNGHIEPTIEUDUNG</div>
              </div>
            </div>
          </div>
          <div className="icon-scroll-media">
            <LazyLoadImage
              src={iconSlider1}
              alt="image"
              height="7px"
              width="50px"
            />
          </div>
        </div>
        {/* slider 5 */}
        <div className="col-12 col-slider-item item-col-2">
          <div className="content-col ">
            <div
              className="content-tag-a"
              onClick={() =>
                openInNewTab(
                  "https://baotintuc.vn/kinh-te/nhieu-doanh-nghiep-viet-nam-tham-du-hoi-cho-cong-nghiep-hannover-o-duc-20230418080839477.htm"
                )
              }
            >
              <div className="content-image">
                <LazyLoadImage className="content-image-nav" src={image5} />
              </div>
              <div className="title-content">
                <div className="title-nav">
                  Nhiều doanh nghiệp Việt Nam tham dự Hội chợ Công nghiệp..."
                </div>
                <div className="content-nav">
                  Tại Hannover Messe - Hội chợ Hàng công nghiệp, công nghệ và
                  công nghệ thông tin (CNTT) hàng đầu thế giới đang diễn ra tại
                  thành phố Hannover, bang Niedersachsen...
                </div>
                <div className="website-name">BAOTINTUC</div>
              </div>
            </div>
          </div>
          <div className="icon-scroll-media">
            <LazyLoadImage
              src={iconSlider1}
              alt="image"
              height="7px"
              width="50px"
            />
          </div>
        </div>
      </Slider>
    </div>
  );
}
