import React from "react";
import "../../assets/sass/historyTwo.scss";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HistoryTwo = () => {
  const i18nextLng =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div className="history-tow-page">
      <div className="history-page-backgroud">
        <div className="history-page-backgroud-img">
          <LazyLoadImage
            className="history-img"
            src={require("../../assets/images/History/backgroud-frame-history.webp")}
            alt="image"
          />
        </div>
        <div
          className={
            i18nextLng === "EN" || i18nextLng === "en"
              ? "history-page-content"
              : " history-page-content history-content-vn"
          }
        >
          <div className="history-page-title title-2">{t("History")}</div>
          <div className="history-content">
            <div className="history-line">
              <div className="history-line-year ">
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? " box-1 box-two-1"
                      : " box-1 box-two-1-vn"
                  }
                >
                  <div className="history-box-year">2006 - 2010</div>
                </div>
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? "box-2 box-two-2 "
                      : " box-2 box-two-2-vn"
                  }
                >
                  <div className="history-box-year">2001 - 2005</div>
                </div>
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? "box-3 box-two-3"
                      : "box-3 box-two-3-vn"
                  }
                >
                  <div className="history-box-year">1997 - 2000</div>
                </div>
              </div>
              <div className="history-content-item">
                {/* <div className="history-circle circle-1 " /> */}
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? "history-circle circle-1 "
                      : "history-circle circle-1-vn "
                  }
                />
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? "history-circle circle-2 "
                      : "history-circle circle-2-vn "
                  }
                />
                <div
                  className={
                    i18nextLng === "EN" || i18nextLng === "en"
                      ? "history-circle circle-3 "
                      : "history-circle circle-3-vn"
                  }
                />
                <div className="history-text">
                  <ul
                    className={
                      i18nextLng === "EN" || i18nextLng === "en"
                        ? " history-text-ul ul-1 ul-two-1"
                        : " history-text-ul ul-1 ul-two-1-vn"
                    }
                  >
                    <li>{t("history.15")}</li>
                    <li>{t("history.16")}</li>
                    {/* {
                                        i18nextLng === "EN" || i18nextLng === "en" ?
                                            <li>{t("history.17")}</li>
                                        :""
                                    } */}
                    <li>{t("history.17")}</li>
                    <li>{t("history.18")}</li>
                    <li>{t("history.19")}</li>
                    <li>{t("history.20")}</li>
                  </ul>
                  <ul
                    className={
                      i18nextLng === "EN" || i18nextLng === "en"
                        ? "history-text-ul ul-2 ul-two-2"
                        : "history-text-ul ul-2-VN ul-two-2"
                    }
                  >
                    <li>{t("history.21")}</li>
                    <li>{t("history.22")}</li>
                    <li>{t("history.23")}</li>
                    <li>{t("history.24")}</li>
                    <li>{t("history.25")}</li>
                    <li>{t("history_new.3")}</li>
                  </ul>
                  <ul
                    className={
                      i18nextLng === "EN" || i18nextLng === "en"
                        ? "history-text-ul ul-3 ul-two-3 ul-3-eng"
                        : "history-text-ul ul-3-VN ul-two-3"
                    }
                  >
                    <li>{t("history_new.4")}</li>
                    <li>{t("history.26")}</li>
                    <li>{t("history_new.5")}</li>
                    <li>{t("history.27")}</li>
                    {i18nextLng === "EN" || i18nextLng === "en" ? (
                      ""
                    ) : (
                      <li>{t("history.28")}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <LazyLoadImage
                className="history-content-img img-two-page "
                src={require("../../assets/images/History/lab-8-image-1.webp")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryTwo;
