import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../assets/sass/mediaAwards.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../assets/images/MediaAwards/image1.webp";
import image2 from "../assets/images/MediaAwards/image2.webp";
import image3 from "../assets/images/MediaAwards/image3.webp";
import image4 from "../assets/images/MediaAwards/image4.webp";
import image5 from "../assets/images/MediaAwards/image5.webp";

// import background from "../assets/images/MediaAwards/background.webp"
// import sliderBackgroundImage from "../assets/images/MediaAwards/slider-background-image.webp"
import iconSlider1 from "../assets/images/banner/icon-banner-slider.webp";
import iconSlider2 from "../assets/images/banner/icon-banner-slider-bettwen.webp";
import iconSlider3 from "../assets/images/banner/icon-banner-slider-end.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MediaDataMobile from "./MediaDataMobile";

function MediaAwards(props) {
  const { t } = useTranslation();
  const ref = useRef({});
  const nextSlider = () => {
    ref.current.slickNext();
  };
  const previous = () => {
    ref.current.slickPrev();
  };
  const settings = {
    dots: false,
    // autoplay: true,
    // speed: 400,
    slidesToScroll: 1,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1365,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 558,
        settings: {
          arrows: false,
          slidesToShow: 1.9,
          slidesToScroll: 2,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 530,
        settings: {
          arrows: false,
          slidesToShow: 1.8,
          slidesToScroll: 1,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 504,
        settings: {
          arrows: false,
          slidesToShow: 1.7,
          slidesToScroll: 1,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 440,
        settings: {
          arrows: false,
          slidesToShow: 1.4,
          slidesToScroll: 1,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 425,
        settings: {
          arrows: false,
          slidesToShow: 1.3,
          slidesToScroll: 1,
          dots: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          infinite: true,
          arrows: true,
          dots: true,
          centerMode: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          infinite: true,
          arrows: true,
          dots: true,
          centerMode: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          infinite: true,
          arrows: true,
          dots: true,
          centerMode: true,
          customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
        },
      },
    ],
  };
  const dataArticle = [
    {
      id: 1,
      image: image5,
      link: "https://baotintuc.vn/kinh-te/nhieu-doanh-nghiep-viet-nam-tham-du-hoi-cho-cong-nghiep-hannover-o-duc-20230418080839477.htm",
      title: "Nhiều doanh nghiệp Việt Nam tham dự Hội chợ Công nghiệp...",
      content:
        "Tại Hannover Messe - Hội chợ Hàng công nghiệp, công nghệ và công nghệ thông tin (CNTT) hàng đầu thế giới đang diễn ra tại thành phố Hannover, bang Niedersachsen...",
      website: "BAOTINTUC",
      icon: iconSlider1,
    },
    {
      id: 2,
      image: image4,
      link: "https://www.tmasolutions.vn/tin-tuc/TMA-25-nam-phat-trien-vung-manh/43901",
      title: "TMA 25 năm phát triển vững mạnh",
      content:
        "TMA tự hào kỷ niệm 25 năm phát triển liên tục, vượt qua nhiều biến động của thị trường để trở thành tập đoàn công nghệ hàng đầu Việt Nam với hơn 3.800 nhân viên, Hành trình 25 năm qua ghi nhận sự nỗ lực...",
      website: "TMASOLUTIONS",
      icon: iconSlider1,
    },
    {
      id: 3,
      image: image2,
      link: "http://www.congnghieptieudung.vn/xay-dung-cong-vien-sang-tao-phan-mem-tao-buoc-dot-pha-moi-phat-trien-cong-nghe-cao-tai-binh-dinh-dt12432",
      title: "Bình Định xây dựng công viên sáng tạo phần mềm đầu tiên...",
      content:
        "Ngày 28/8, tại Thung lũng sáng tạo Quy Hòa, phường Ghềnh Ráng, TP Quy Nhơn - Công ty TNHH Giải pháp phần mềm Tường Minh Bình Định thuộc Công ty TMA Solutions...",
      website: "CONGNGHIEPTIEUDUNG",
      icon: iconSlider1,
    },
    {
      id: 4,
      image: image1,
      link: "https://www.techtimes.vn/binh-dinh-xay-dung-cong-vien-sang-tao-phan-mem-dau-tien-tong-von-dau-tu-8-trieu-usd/",
      title: " Xây dựng Công viên sáng tạo phần mềm tạo bước đột phá...",
      content:
        "Công viên sáng tạo TMA có tổng vốn đầu tư khoảng 8 triệu USD, được chia làm 2 giai đoạn, dự kiến đi vào hoạt động sau 3 năm xây dựng. Sau 15 năm, công viên sẽ có...",
      website: "TECHTIMES",
      icon: iconSlider2,
    },
    {
      id: 5,
      image: image3,
      link: "https://vneconomy.vn/tap-doan-hung-thinh-hop-tac-chien-luoc-cung-cong-ty-tma-solutions.htm",
      title: "Tập đoàn Hưng Thịnh hợp tác chiến lược cùng công ty TMA...",
      content:
        "Tập đoàn Hưng Thịnh vừa ký kết hợp tác chiến lược với Công ty TNHH giải pháp phần mềm Tường Minh (TMA Solutions). Sự kiện đánh dấu mốc quan trọng trong mối...",
      website: "VNECONOMY",
      icon: iconSlider3,
    },
    // {
    //   id: 5,
    //   image: image4,
    //   link: "https://www.tmasolutions.vn/tin-tuc/TMA-25-nam-phat-trien-vung-manh/43901",
    //   title: "TMA 25 năm phát triển vững mạnh",
    //   content:
    //     "TMA tự hào kỷ niệm 25 năm phát triển liên tục, vượt qua nhiều biến động của thị trường để trở thành tập đoàn công nghệ hàng đầu Việt Nam với hơn 4.000 nhân viên. Hành trình 25 năm qua ghi nhận sự nỗ lực...",
    //   website: "TMASOLUTIONS",
    //   icon: iconSlider1,
    // },
    // {
    //   id: 6,
    //   image: image2,
    //   link: "http://www.congnghieptieudung.vn/xay-dung-cong-vien-sang-tao-phan-mem-tao-buoc-dot-pha-moi-phat-trien-cong-nghe-cao-tai-binh-dinh-dt12432",
    //   title: "Bình Định xây dựng công viên sáng tạo phần mềm đầu tiên...",
    //   content:
    //     "Ngày 28/8, tại Thung lũng sáng tạo Quy Hòa, phường Ghềnh Ráng, TP Quy Nhơn - Công ty TNHH Giải pháp phần mềm Tường Minh Bình Định thuộc Công ty TMA Solutions...",
    //   website: "CONGNGHIEPTIEUDUNG",
    //   icon: iconSlider1,
    // },
    // {
    //   id: 7,
    //   image: image1,
    //   link: "https://www.techtimes.vn/binh-dinh-xay-dung-cong-vien-sang-tao-phan-mem-dau-tien-tong-von-dau-tu-8-trieu-usd/",
    //   title: " Xây dựng Công viên sáng tạo phần mềm tạo bước đột phá...",
    //   content:
    //     "Công viên sáng tạo TMA có tổng vốn đầu tư khoảng 8 triệu USD, được chia làm 2 giai đoạn, dự kiến đi vào hoạt động sau 3 năm xây dựng. Sau 15 năm, công viên sẽ có...",
    //   website: "TECHTIMES",
    //   icon: iconSlider2,
    // },
    // {
    //   id: 8,
    //   image: image3,
    //   link: "https://vneconomy.vn/tap-doan-hung-thinh-hop-tac-chien-luoc-cung-cong-ty-tma-solutions.htm",
    //   title: "Tập đoàn Hưng Thịnh hợp tác chiến lược cùng công ty TMA...",
    //   content:
    //     "Tập đoàn Hưng Thịnh vừa ký kết hợp tác chiến lược với Công ty TNHH giải pháp phần mềm Tường Minh (TMA Solutions). Sự kiện đánh dấu mốc quan trọng trong mối...",
    //   website: "VNECONOMY",
    //   icon: iconSlider3,
    // },
  ];

  function openInNewTab(url) {
    window.open(url, "_blank").focus();
  }

  const language =
    localStorage.getItem("lang") === null
      ? localStorage.getItem("i18nextLng")
      : localStorage.getItem("lang");

  return (
    <div className="media-awards" id="media-awards">
      <div className="media-awards-backgroud">
        <div
          className={
            language === "EN" || language === "en" || language === "en-US"
              ? " container-meida-desktop container-media-en"
              : " container-meida-desktop"
          }
        >
          <div className="titile">{t("Media.Awards")}</div>
          <div className="d-flex ">
            <div className="col-12 content-contain padding-left-0 padding-right-0 ">
              <Slider
                ref={(slider) => (ref.current = slider)}
                {...settings}
                className="slider-desk-top"
              >
                {dataArticle.map((item) => {
                  return (
                    <div
                      className={`col-12 col-slider-item ${item.class}`}
                      key={item.id}
                    >
                      <div className="content-col ">
                        <div
                          className="content-tag-a"
                          onClick={() => openInNewTab(item.link)}
                        >
                          <div className="content-image">
                            <LazyLoadImage
                              className="content-image-nav"
                              src={item.image}
                            />
                          </div>
                          <div className="title-content">
                            <div className="title-nav">{item.title}</div>
                            <div className="content-nav">{item.content}</div>
                            <div className="website-name">{item.website}</div>
                          </div>
                        </div>
                      </div>
                      <div className="icon-scroll-media">
                        <LazyLoadImage
                          src={item.icon}
                          alt="image"
                          height="7px"
                          width="50px"
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>

              {/* slider mobiel */}
              <MediaDataMobile ref={ref} settings={settings} />
              {/* // */}

              <div className="explore-more">
                <div
                  className="content-tag-a"
                  onClick={() =>
                    openInNewTab(
                      "https://www.tmasolutions.vn/truyen-thong-va-giai-thuong"
                    )
                  }
                >
                  <Button variant="link" className="btn-more">
                    {t("Explore.more")}
                  </Button>
                </div>
              </div>
              <div className="next-slider" onClick={nextSlider}>
                <LazyLoadImage
                  src={require("../assets/images/MediaAwards/icon-next.webp")}
                  alt="image"
                  width="88px"
                  height="88px"
                />
              </div>
              <div className="prev-slider" onClick={previous}>
                <LazyLoadImage
                  src={require("../assets/images/MediaAwards/icon-prev.webp")}
                  alt="image"
                  width="88px"
                  height="88px"
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaAwards;
