import React from "react";
// import TMAInnovationVN from ''
import "../../assets/sass/TMAInnovationVN.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function TMAInnovationVN() {
  return (
    <div className="tma-innovation-vn">
      <div className="tma-innovation-vn-contain">
        <div className="d-flex">
          <div className="col-xl-6 col-left">
            <div className="title">
              <div className="title-top">TMA Innovation</div>
              <div className="title-bottom">
                Cung cấp sản phẩm và giải pháp công nghệ tiên tiến cho nhiều
                lĩnh vực
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-right">
            <div className="backgroud-right">
              <div className="backroud-frame">
                <LazyLoadImage
                  src={require("../../assets/images/AboutUs/backgroud-frame.webp")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content-box">
          <div className="d-flex">
            <div className="box-1">
              <div className="box-text">Vườn ươm doanh nghiệp</div>
              <div>
                <LazyLoadImage
                  src={require("../../assets/images/Members/TMAInnovationVN-image-1.webp")}
                  alt=""
                />
              </div>
            </div>
            <div className="box-2">
              <div className="box-text">Sản phẩm & giải pháp Công nghệ 4.0</div>
              <div>
                <LazyLoadImage
                  src={require("../../assets/images/Members/TMAInnovationVN-image-2.webp")}
                  alt=""
                />
              </div>
            </div>
            <div className="box-3">
              <div className="box-text">Nghiên cứu & phát triển</div>
              <div>
                <LazyLoadImage
                  src={require("../../assets/images/Members/TMAInnovationVN-image-3.webp")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TMAInnovationVN;
