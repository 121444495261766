import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationVI from "./assets/locales/vi/translation.json";

const fallbackLng = ["en"];

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};
function refreshPage() {
  const reloadCount = localStorage.getItem("reloadCount");
  if (reloadCount < 2) {
    localStorage.setItem("reloadCount", String(reloadCount + 1));
    window.location.reload();
  } else {
    localStorage.removeItem("reloadCount");
  }
}

let hostName = window.location.hostname;
i18n.on("languageChanged", (fallbackLng) => {
  if (hostName === "www.tmatechgroup.com") {
    localStorage.setItem("i18nextLng", "en");
    localStorage.setItem("lang", "en");
  } else if (hostName === "www.tmatechgroup.vn") {
    localStorage.setItem("i18nextLng", "vi");
    localStorage.setItem("lang", "vi");
  } else {
    return;
  }
  refreshPage();
});
// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources,
//     fallbackLng,

//     detection: {
//       checkWhitelist: true
//     },

//     debug: false,

//     whitelist: availableLanguages,

//     interpolation: {
//       escapeValue: false
//     },
//     react: {
//       wait: true
//     }
//   });
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

export default i18n;
