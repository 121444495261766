import React from "react";
import { Image } from "react-bootstrap";
import "../assets/sass/contactUs.scss";
import { useTranslation } from "react-i18next";
import line from "../assets/images/ContactUs/line.webp";
import phone from "../assets/images/ContactUs/icon-phone.webp";
import IconEmail from "../assets/images/ContactUs/icon-mail.webp";
import IconWebsite from "../assets/images/ContactUs/icon-website.webp";
import IconWebAdress from "../assets/images/ContactUs/website.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ContactUs(props) {
    const language = localStorage.getItem("lang") === null ? localStorage.getItem("i18nextLng") : localStorage.getItem("lang");
    const { t } = useTranslation();
    return (
        <div className="contact-us" id="contact-us">
            <div className="container contact-us-container">
                <div className="contact-us-content">
                    <div className="contact-us-title">{t("contact.us")}</div>
                    <div className="contact-us-contact">
                        <div className="backgroud-contact-us">
                            <div className="contact-us-contact-background contact-us-contact-background-d-flex backgroud-image">
                                <div className="col-lg-6 col-sm-12 padding-right-0 contact-us-contact-phone-nav">
                                    <div className="contact-us-contact-phone">
                                        <div className="col-lg-6 col-sm-6 col-xs-12 contact-phone-left">
                                            <div className="phone-contact padding-top-item">
                                                <div className="contruy-name contruy-name-vietnam">
                                                    {t("viet.nam")}
                                                    <Image className="padding-left-4" src={line} />
                                                </div>
                                                <div className="number-phone number-phone-center">
                                                    <a className="text-decoration" style={{ color: "#222222" }} href="tel:+842839978000">
                                                        +84 28 3997 8000
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="padding-top-item padding-top-item-america">
                                                <div className="contruy-name contruy-name-america">
                                                    {t("North.America")}
                                                    <Image className="padding-left-4" src={line} />
                                                </div>
                                                <div className="number-phone  number-phone-center">
                                                    <a className="text-decoration" style={{ color: "#222222" }} href="tel:+18442244188">
                                                        +1 802 735 1392
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="padding-top-item">
                                                <div className="contruy-name contruy-name-europe">
                                                    {t("Europe")}
                                                    <Image className="padding-left-4" src={line} />
                                                </div>
                                                <div className="number-phone number-phone-center">
                                                    <a className="text-decoration" style={{ color: "#222222" }} href="tel:+33744774924">
                                                        +33 744 774 924
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-xs-12 contact-phone-right">
                                            <div className="padding-top-item">
                                                <div className="contruy-name contruy-name-australia">
                                                    {t("Australia")}
                                                    <Image className="padding-left-4" src={line} />
                                                </div>
                                                <div className="number-phone">
                                                    <a className="text-decoration" style={{ color: "#222222" }} href="tel:+61411276342">
                                                        +61 411 276 342
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="padding-top-item">
                                                <div className="contruy-name contruy-name-japan">
                                                    {t("Japan")}
                                                    <Image className="padding-left-4" src={line} />
                                                </div>
                                                <div className="number-phone">
                                                    <a className="text-decoration" style={{ color: "#222222" }} href="tel:+815054909867">
                                                        {/* +81 364 324 994 */}
                                                        +81 50 5490 9867
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="icon-phone">
                                            <LazyLoadImage src={phone} className="icon-phone-img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-phone-contact">
                                    {language === "en" || language === "EN" || language === "EN-US" ? (
                                        <>
                                            {" "}
                                            <div className="d-flex item-contact">
                                                <div className="icon-right col-2 icon-email icon-website">
                                                    <LazyLoadImage className="icon-contacts" src={IconEmail} />
                                                </div>
                                                <div className="content-item">
                                                    <span className="title-email">Email</span>
                                                    <div className="padding-top-12 content-email">
                                                        <div>
                                                            <a
                                                                href="mailto:sales@tmasolutions.com"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                sales@tmasolutions.com
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="mailto:contact@tma-innovationpark.com"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                contact@tma-innovationpark.com
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="mailto:contact@tmainnovation.com"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                contact@tmainnovation.com
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex item-2 item-contact">
                                                <div className="icon-right col-2 icon-website ">
                                                    <LazyLoadImage className="icon-contacts" src={IconWebsite} />
                                                </div>
                                                <div className="content-item">
                                                    <span className="title-email">Website</span>
                                                    <div className="padding-top-12 content-email">
                                                        <div>
                                                            <a
                                                                href="https://www.tmasolutions.com/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tmasolutions.com
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="https://www.tma-innovationpark.com/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tma-innovationpark.com
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="https://www.tmainnovation.com/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tmainnovation.com
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <div className="d-flex item-contact">
                                                <div className="icon-right col-2 icon-email icon-website">
                                                    <LazyLoadImage className="icon-contacts" src={IconEmail} />
                                                </div>
                                                <div className="content-item">
                                                    <span className="title-email">Email</span>
                                                    <div className="padding-top-12 content-email">
                                                        <div>
                                                            <a
                                                                href="mailto:sales@tmasolutions.vn"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                sales@tmasolutions.vn
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="mailto:contact@tma-binhdinh.vn"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                contact@tma-binhdinh.vn
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="mailto:contact@tmainnovation.vn"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                            >
                                                                contact@tmainnovation.vn
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex item-2 item-contact">
                                                <div className="icon-right col-2 icon-website ">
                                                    <LazyLoadImage className="icon-contacts" src={IconWebsite} />
                                                </div>
                                                <div className="content-item">
                                                    <span className="title-email">Website</span>
                                                    <div className="padding-top-12 content-email">
                                                        <div>
                                                            <a
                                                                href="https://www.tmasolutions.vn/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tmasolutions.vn
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="https://www.tma-binhdinh.vn/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tma-binhdinh.vn
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="https://www.tmainnovation.vn/"
                                                                target="_blank"
                                                                className="text-decoration"
                                                                style={{ color: "#FFFFFF" }}
                                                                rel="noreferrer"
                                                            >
                                                                www.tmainnovation.vn
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="d-flex item-3 item-contact">
                                        <div className="icon-right col-2 icon-website ">
                                            <LazyLoadImage className="icon-contacts" src={IconWebAdress} />
                                        </div>
                                        <div className="content-item">
                                            <span className="title-email">{t("Address")}</span>
                                            <div className="padding-top-12 content-address">
                                                {language === "vi" || language === "VI" ? (
                                                    <div className="address-cotnent">
                                                        Tòa nhà TMA, Đường số 10, Công viên Phần mềm Quang Trung, Quận 12 HCMC
                                                    </div>
                                                ) : (
                                                    <div className="address-cotnent">
                                                        Street #10, Quang Trung Software City, 1 To Ky, District 12, HCMC
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
