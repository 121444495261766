import axiosClient from "./axiosClient";

const apiURL = "/GeneralNumber";

const generalNumberApi = {
  getNumberTma: () => {
    const url = `${apiURL}/get-numbertma`;
    return axiosClient.get(url);
  },
};

export default generalNumberApi;
